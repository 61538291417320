const blockuser = (state = {}, action) => {
  const { payload, type } = action
  switch (type) {
    case 'SET_BLOCKUSER':
      return payload
    default:
      return state
  }
}

export default blockuser
