const getiOSDetails = async country => {
  const countryCode = country ? `${country}/` : 'us/'
  const dateNow = new Date().getTime()

  return fetch(
    `https://itunes.apple.com/${countryCode}lookup?bundleId=com.trally&date=${dateNow}`
  )
    .then(res => res.json())
    .then(json => {
      if (json.resultCount) {
        const data = json.results[0]
        const storeUrl = `itms-apps://apps.apple.com/${countryCode}app/id${data.trackId}`
        return {
          ...data,
          storeUrl
        }
      }
      return {
        error: 'No info about this app.'
      }
    })
}

export default getiOSDetails
