const categories = (state = [], action) => {
  const { payload, type } = action

  switch (type) {
    case 'SET_CATEGORIES':
      return payload

    default:
      return state
  }
}

export default categories
