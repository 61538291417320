import { Fragment, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import useTranslation from 'next-translate/useTranslation'
import Image from 'next/image'

import Col from 'components/Col'
import Text from 'components/Text'
import Icon from 'components/Icon'
import Clickable from 'components/Clickable'
import Button from 'components/Button'
import Row from 'components/Row'

import detectDevice from 'core/detectDevice'
import getiOSDetails from 'core/getiOSDetails'

import theme from 'lib/theme'
import env from 'lib/env'

const OuterContainer = styled(Row)`
  position: relative;
  height: ${props => props.height};
`

const Container = styled(Row)`
  position: fixed;
  z-index: 1000;
  justify-content: space-between;
  align-items: center;
`

function AppPopup() {
  const dispatch = useDispatch()
  const { t } = useTranslation('common')
  const [showPopup, setShowPopup] = useState(null)
  const [link, setLink] = useState('')
  const [iosDetails, setiOSDetails] = useState(null)
  const [height, setHeight] = useState(0)

  useEffect(() => {
    let device = detectDevice()
    if (device && (device === 'android' || device === 'ios')) {
      setShowPopup(device)
      let tempLink =
        env.API_URL &&
        (env.API_URL.includes('localhost') ||
          env.API_URL.includes('192.168') ||
          env.API_URL.includes('stagingapi.trally'))
          ? `https://trallystaging.page.link/download`
          : `https://trally.page.link/download`

      setLink(tempLink)
      setHeight(
        device
          ? device === 'android'
            ? '56px'
            : device === 'ios' && '87px'
          : '0px'
      )
      dispatch({
        type: 'SET_DOWNLOAD_POPUP_STATUS',
        payload: {
          visible: device,
          height: device
            ? device === 'android'
              ? 56
              : device === 'ios' && 87
            : 0
        }
      })
    } else {
      dispatch({
        type: 'SET_DOWNLOAD_POPUP_STATUS',
        payload: null
      })
    }
  }, [dispatch])

  useEffect(() => {
    async function getDetails() {
      if (showPopup && showPopup === 'ios') {
        const data = await getiOSDetails()
        setiOSDetails(data)
      }
    }

    getDetails()
  }, [showPopup])

  const closePopup = () => {
    dispatch({
      type: 'SET_DOWNLOAD_POPUP_STATUS',
      payload: null
    })
    setShowPopup(null)
  }

  const openApp = async () => {
    if (!link) return
    window.open(link, '_self')
  }

  if (!showPopup) return null

  return (
    <OuterContainer height={height}>
      <Container
        noFlex
        bg={
          showPopup === 'android' ? theme.LIGHT_GREEN_4 : theme.GRAY_6
        }
        wid='100vw'
        top='0'
        left='0'
        ht={showPopup === 'android' ? '56px' : '87px'}
        pad='9.5px 14px'
      >
        {showPopup === 'android' ? (
          <Fragment>
            <Clickable onClick={() => closePopup()}>
              <Icon type='close' size='12px' />
            </Clickable>
            <Col marg='0 25px'>
              <Text size='16px'>
                {t('GENERAL.download-app-popup-1')}{' '}
                <strong>{t('GENERAL.download-app-popup-2')}</strong>{' '}
                {t('GENERAL.download-app-popup-3')}
              </Text>
            </Col>
            <Button
              black
              text={t('GENERAL.download-app-popup-button')}
              fontSize='14px'
              pad='0 17.5px'
              ht='32px'
              hasRadius='10px'
              onClick={openApp}
            />
          </Fragment>
        ) : (
          <Fragment>
            <Clickable onClick={() => closePopup()}>
              <Icon type='close' size='12px' />
            </Clickable>
            <Row marg='0 14px'>
              <Image
                src={require('assets/trally-icon.png')}
                style={{
                  width: 69,
                  height: 69
                }}
                alt='trally icon'
              />
              <Col
                style={{
                  justifyContent: 'center'
                }}
                marg='0 0 0 6px'
              >
                <Text size='15px' lineHeight='17.9px' weight='500'>
                  {iosDetails?.trackName}
                </Text>
                <Text
                  marg='1px 0 0 0'
                  size='12px'
                  lineHeight='14.32px'
                >
                  {iosDetails?.sellerName}
                </Text>

                {iosDetails?.averageUserRating ? (
                  <Row noFlex marg='3px 0'>
                    {Array(Math.round(iosDetails.averageUserRating))
                      .fill(null)
                      .map((_, i) => {
                        return (
                          <Icon
                            type='star'
                            size='8px'
                            key={i}
                            marg='0 2px 0 0'
                          />
                        )
                      })}
                  </Row>
                ) : (
                  ''
                )}
                <Text
                  marg='1px 0 0 0'
                  size='13px'
                  lineHeight='14.32px'
                >
                  GET - On the App Store
                </Text>
              </Col>
            </Row>
            <Button
              bg='transparent'
              noShadow
              pad='0 17.5px'
              ht='32px'
              hasRadius='10px'
              onClick={openApp}
            >
              <Text size='16px' color={theme.BLUE_5}>
                {t('GENERAL.download-app-popup-button')}
              </Text>
            </Button>
          </Fragment>
        )}
      </Container>
    </OuterContainer>
  )
}

export default AppPopup
