import styled from 'styled-components'
import { useRouter } from 'next/router'

import Row from 'components/Row'
import Col from 'components/Col'
import Text from 'components/Text'
import Avatar from 'components/Avatar'

const RowWrapper = styled.div`
  cursor: pointer;
  &:hover {
    background-color: rgba(196, 196, 196, 0.2);
  }
`

function Hosts({ hosts, handleClose }) {
  const router = useRouter()
  if (hosts?.length <= 0) return null
  return (
    <Col>
      <Text
        pad='0px 20px'
        weight='700'
        color='#ABABAB'
        lineHeight={'14px'}
        size='12px'
      >
        HOSTS
      </Text>
      <Row marg='10px 0 10px 0'>
        <Col>
          {hosts?.length > 0 &&
            hosts.map((singleHost, i) => (
              <RowWrapper
                key={i}
                onClick={() => {
                  router.push(`/profile/${singleHost._id}`)
                  handleClose()
                }}
              >
                {/* <Link
                  to={{
                    pathname: '/search-result',
                    stateData: {
                      userSearch: singleHost.name,
                    },
                  }}
                  style={{ textDecoration: 'none' }}
                > */}
                <Row center pad='8px 20px'>
                  <Row noFlex minWid='35px'>
                    <Avatar
                      size={35}
                      uri={
                        singleHost.avatar ? singleHost.avatar : null
                      }
                    />
                  </Row>
                  <Text
                    weight='600'
                    lineHeight={'19px'}
                    size={16}
                    marg='0px 0px 0px 12px'
                  >
                    {singleHost.name}
                  </Text>
                </Row>
                {/* </Link> */}
              </RowWrapper>
            ))}
        </Col>
      </Row>
    </Col>
  )
}

export default Hosts
