let isInit = null
let queue = []
let flushQueueTimer = null
let amplitude = null

const importAmplitude = async () => {
  amplitude = await (await import('amplitude-js')).default
}

var errorCallback = function () {
  console.error('[Amplitude] - Event sending failed!')
}

const createAmplitudeInstance = async ({
  isLoggedIn,
  email,
  name,
  is_host,
  signup_date,
  userId
}) => {
  if (!amplitude) await importAmplitude()

  //user logged out reset prev instance
  if (isInit === 'user' && !isLoggedIn) {
    isInit = null
    await resetAmplitude()
  }

  amplitude.getInstance().onInit(client => {
    let tempInit = client.getUserId() ? 'user' : 'guest'
    isInit = tempInit

    //running all those instances that were added to queue
    //due to amplitude not being initialized
    clearTimeout(flushQueueTimer)
    flushQueueTimer = setTimeout(() => {
      if (!queue || (queue && queue.length <= 0)) return
      for (let i = 0; i < queue.length; i++) {
        if (queue[i].type === 'default') {
          logEvent(
            {
              ...queue[i]
            },
            tempInit
          )
        } else if (queue[i].type === 'custom') {
          logCustomEvent(
            {
              ...queue[i]
            },
            tempInit
          )
        }
      }
      queue = []
    }, 1000)
  })

  if (isLoggedIn && userId && isInit !== 'user') {
    if (!isInit) {
      await amplitude
        .getInstance()
        .init(process.env.NEXT_PUBLIC_AMPLITUDE_API, userId)
    } else if (isInit === 'guest') {
      await amplitude.getInstance().setUserId(userId)
    }
    const identify = new amplitude.Identify()
      .set('email', email)
      .set('name', name)
      .set('is_host', is_host)
      .set('signup_date', signup_date)
    await amplitude.getInstance().identify(identify)
  } else if (!isInit) {
    await amplitude
      .getInstance()
      .init(process.env.NEXT_PUBLIC_AMPLITUDE_API)
  }
}

const logEvent = async ({ name, type }, isCustomInit) => {
  if (!amplitude) await importAmplitude()

  if (!isInit && !isCustomInit) {
    queue.push({ name, type, type: 'default' })
  } else {
    amplitude
      .getInstance()
      .logEvent(
        (type === 'click' ? 'Click - ' : 'Pageview - ') + name,
        null,
        () => {},
        errorCallback
      )
  }
}

const logCustomEvent = async ({ name, data }, isCustomInit) => {
  if (!amplitude) await importAmplitude()
  if (!isInit && !isCustomInit) {
    queue.push({ name, data, type: 'custom' })
  } else {
    amplitude
      .getInstance()
      .logEvent(name, data, () => {}, errorCallback)
  }
}

const resetAmplitude = async () => {
  if (!amplitude) await importAmplitude()
  if (isInit === 'user') {
    let identify = new amplitude.Identify()
      .unset('email')
      .unset('name')
      .unset('is_host')
      .unset('signup_date')
    amplitude.getInstance().identify(identify)
  }
  isInit = null
  amplitude.getInstance().setUserId(null)
  amplitude.getInstance().regenerateDeviceId()
}

const getAmplitudeStats = async () => {
  if (!amplitude) await importAmplitude()
  return {
    userId: amplitude.getInstance().getUserId(),
    deviceId: amplitude.getInstance().getDeviceId(),
    sessionId: amplitude.getInstance().getSessionId()
  }
}
export {
  createAmplitudeInstance,
  resetAmplitude,
  logEvent,
  logCustomEvent,
  getAmplitudeStats
}
