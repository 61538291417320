const recordings = (state = null, action) => {
  const { payload, type } = action

  switch (type) {
    case 'SET_RECORDINGS':
      return payload

    case 'REMOVE_RECORDING':
      return state && state.length > 0
        ? state.filter(
            x =>
              !(
                x._id + '' === payload.recordingId &&
                x.link._id === payload.downloadLinkId
              )
          )
        : null

    case 'EMPTY_RECORDINGS':
      return null

    default:
      return state
  }
}

export default recordings
