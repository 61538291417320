import { useState } from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import Image from 'next/image'

import circle from 'assets/avatar-circle.png'
import noProfilePicture from 'assets/no-profile-picture.png'

import Icon from 'components/Icon'
import Spinner from 'components/Spinner'
import theme from 'lib/theme'

const Wrapper = styled.div`
  width: ${props => props.size + 'px'};
  height: ${props => props.size + 'px'};
  ${props => !props.noBg && `background-size: ${props.size + 'px'};`}
  display: flex;
  align-items: center;
  justify-content: center;

  ${props =>
    props.uri &&
    `
    background-image: ${props.noCircle ? '' : `url(${circle.src})`}; 
    background-repeat: no-repeat;
  `}
`

function Avatar(props) {
  const [err, setErr] = useState(false)
  const { uri, size, noCircle, small, navBar, edit, noBg } = props
  const loading = useSelector(
    state => state.appState.loadingButton === 'avatar'
  )

  if (!uri && navBar) {
    return <Icon type='profile' size={'25px'} />
  }

  return (
    <Wrapper
      size={size}
      uri={uri || noProfilePicture}
      noCircle={noCircle}
      noBg={noBg}
    >
      {loading && edit && <Spinner />}

      {!loading && !err && (
        <Image
          src={uri || noProfilePicture}
          alt='avatar'
          style={{
            width: size ? size - 6 : 50,
            height: size ? size - 6 : 50,
            borderRadius: props.hasRadius ? props.hasRadius : '50%',
            textIndent: -9999,
            alignSelf: 'center',
            border: `${
              props.noBorder
                ? 'none'
                : small
                ? `1px solid ${theme.WHITE}`
                : `3px solid ${theme.WHITE}`
            }`
          }}
          width={size - 6}
          height={size - 6}
          onError={() => setErr(true)}
        />
      )}
    </Wrapper>
  )
}

export default Avatar
