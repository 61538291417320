import Image from 'next/image'
import styled, { keyframes } from 'styled-components'

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`

const Container = styled.div`
  animation: ${rotate} 1s linear infinite;
  margin: 0 auto;
`

const Spinner = ({ dimensions = 25, type }) => (
  <Container>
    <Image
      style={{ width: dimensions, height: dimensions }}
      src={
        type && type === 'white'
          ? require('assets/icons/loading-white.png')
          : require('assets/icons/loading.png')
      }
      alt='loading'
    />
  </Container>
)

export default Spinner
