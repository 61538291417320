import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useRouter } from 'next/router'

import Row from 'components/Row'
import Col from 'components/Col'
import Text from 'components/Text'
import Avatar from 'components/Avatar'

const RowWrapper = styled.div`
  cursor: pointer;
  &:hover {
    background-color: rgba(196, 196, 196, 0.2);
  }
`
function Recents({ destinations, handleClose }) {
  const router = useRouter()
  const [icon, setIcon] = useState('')

  useEffect(() => {
    async function loadIcons() {
      setIcon(await (await import('lib/icons')).default['location'])
    }
    loadIcons()
  }, [])

  if (destinations?.length <= 0) return null

  return (
    <Col>
      <Text
        weight='700'
        color='#ABABAB'
        lineHeight='14px'
        size='12px'
        pad='0px 20px'
      >
        DESTINATIONS
      </Text>
      <Row marg='10px 0 10px 0'>
        <Col>
          {destinations?.length > 0 &&
            destinations.map(({ name, city, country, trips }, i) => (
              <RowWrapper
                key={i}
                onClick={() => {
                  router.push(`/search-results/${name}`, {
                    searchText: name,
                    searchParams: {
                      city: city,
                      country: country
                    }
                  })

                  handleClose()
                }}
              >
                <Row center between pad='8px 20px'>
                  <Row center>
                    {icon && <Avatar size={22} uri={icon} noCircle />}
                    <Text
                      weight='600'
                      lineHeight={'19px'}
                      size={16}
                      marg='0px 0px 0px 12px'
                    >
                      {name}
                    </Text>
                  </Row>
                  <Text weight='600' lineHeight={'19px'} size={16}>
                    {trips}
                  </Text>
                </Row>
              </RowWrapper>
            ))}
        </Col>
      </Row>
    </Col>
  )
}

export default Recents
