import { useState, useRef, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { useRouter } from 'next/router'
import Image from 'next/image'

import Row from 'components/Row'
import Text from 'components/Text'
import Icon from 'components/Icon'
import Clickable from 'components/Clickable'
import Col from 'components/Col'

import getNotifTime from 'lib/getNotifTime'
import theme from 'lib/theme'

import redirector from 'core/redirector'

import ItemText from './ItemText'

const NotificationMenu = styled(Col)`
  width: 311px;
  height: 108px;
  background-color: ${theme.WHITE};
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  z-index: 1;
  position: absolute;
  right: 16px;
  top: 56px;
  padding: 4px 0;
`

const NotificationMenuOption = styled(Clickable)`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 50px;
  padding: 13px 0 14.5px 0;
  max-height: 50px;
  &:hover {
    background-color: ${theme.gray12};
  }
`

const StyledImg = styled(Image)`
  margin-right: 16px;
  border-radius: 48px;
`

const StyledClickable = styled(Clickable)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 42.8px;
  height: 38px;
  border-radius: 50%;
  ${props =>
    props.opened && `background-color: rgba(34, 25, 26, 0.2);`};
  ${props =>
    props.opened && `box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1);`};
`

const Item = ({
  data,
  isMobile,
  t,
  setLoading,
  location,
  isDesktop
}) => {
  const [notificationMenu, setNotificationMenu] = useState(false)
  const notificationRef = useRef()

  const user = useSelector(state => state.user)
  const router = useRouter()
  const dispatch = useDispatch()

  useEffect(() => {
    if (!notificationMenu) {
      document.removeEventListener('mousedown', handleClickOutside)
      return
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [notificationMenu])

  function handleClickOutside(e) {
    if (notificationRef?.current?.contains(e.target)) return
    setNotificationMenu(false)
  }

  const deleteThisNotification = async () => {
    setLoading(true)

    const deleteNotification = await (
      await import('actions/user/deleteNotification')
    ).default
    if (data && data._id) {
      dispatch(
        deleteNotification({
          data: {
            notificationId: data._id
          },
          action: () => setLoading(false)
        })
      )
    }
  }

  const stopThisNotification = async () => {
    setLoading(true)

    const changeNotificationSettings = await (
      await import('actions/user/changeNotificationSettings')
    ).default

    if (data && data.notificationType) {
      let notificationSettings =
        user && user.notificationSettings
          ? user.notificationSettings
          : {
              tourReminder: true,
              rateReminder: true,
              suggestionsForFollowings: true,
              popularTourSuggestions: true,
              newFollower: true,
              referralUpdates: true,
              tourBooked: true,
              messageReminder: true
            }

      notificationSettings[`${data.notificationType}`] = false

      dispatch(
        changeNotificationSettings({
          data: {
            notificationSettings
          },
          action: () => {
            setLoading(false)
          }
        })
      )
    }
  }

  const { users, trip, sender } = data
  let imageSource =
    sender && sender.avatar
      ? sender.avatar
      : users?.length
      ? users[0].avatar
      : trip?._id
      ? trip.host.avatar
      : require('assets/notification-blank.png')

  return (
    <Row
      relative
      ht='110px'
      center
      bg={data.read ? '#fff' : theme.DARK_TEAL_20}
      borderTop={
        isDesktop
          ? location !== 'first'
            ? `1px solid ${theme.gray4}`
            : 'none'
          : `1px solid ${theme.gray4}`
      }
      borderBottom={
        location === 'last' ? `1px solid ${theme.gray4}` : 'none'
      }
      pad='14px 0 14px 16px'
      wid={isMobile ? '100vw' : '375px'}
      minHt='110px'
    >
      <Row noFlex wid='90%'>
        <Clickable
          marg='1px 0 0'
          onClick={() => redirector({ router, data, dispatch })}
        >
          <Row center>
            <StyledImg
              src={imageSource}
              alt='notification image'
              width={48}
              height={48}
            />

            <Col between>
              <ItemText item={data} />
              <Text size='12px' color={theme.GRAY_40}>
                {getNotifTime(data.updatedAt)}
              </Text>
            </Col>
          </Row>
        </Clickable>
      </Row>
      <StyledClickable
        marg='0 15px 0 17px'
        opened={notificationMenu}
        onClick={() => setNotificationMenu(!notificationMenu)}
      >
        <Icon type='more' size='20px' />
      </StyledClickable>

      {notificationMenu && (
        <NotificationMenu ref={notificationRef}>
          <NotificationMenuOption
            onClick={() => deleteThisNotification()}
          >
            <Icon
              type='delete-filled'
              size='30px'
              ht='30px'
              marg='0 21.25px'
            />
            <Text
              pad='4px 0 0 0'
              weight='400'
              size='14px'
              lineHeight='16.71px'
              spacing='0.2px'
            >
              {t('NOTIFICATION.delete-this-notification')}
            </Text>
          </NotificationMenuOption>
          <NotificationMenuOption
            onClick={() => stopThisNotification()}
          >
            <Icon
              type='notification-stop'
              size='30px'
              ht='30px'
              marg='0 21.25px'
            />
            <Text
              pad='4px 0 0 0'
              center
              weight='400'
              size='14px'
              lineHeight='16.71px'
              spacing='0.2px'
            >
              {t('NOTIFICATION.mute-notification-like-this')}
            </Text>
          </NotificationMenuOption>
        </NotificationMenu>
      )}
    </Row>
  )
}

export default Item
