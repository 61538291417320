import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useTranslation from 'next-translate/useTranslation'

import theme from 'lib/theme'

import Row from 'components/Row'
import Switch from 'components/Switch'
import Text from 'components/Text'
import Col from 'components/Col'

const NotificationSettings = () => {
  const { t } = useTranslation('common')
  const dispatch = useDispatch()
  const user = useSelector(state => state.user)

  const notificationTypes = [
    {
      title: t('SETTINGS.tour-reminder'),
      field: 'tourReminder'
    },
    {
      title: t('SETTINGS.rate-reminder'),
      field: 'rateReminder'
    },
    {
      title: t('SETTINGS.tour-suggestions-from-following'),
      field: 'suggestionsForFollowings'
    },
    {
      title: t('SETTINGS.popular-tour-suggestions'),
      field: 'popularTourSuggestions'
    },
    {
      title: t('SETTINGS.new-follower'),
      field: 'newFollower'
    },
    {
      title: t('SETTINGS.referral-updates'),
      field: 'referralUpdates'
    },
    {
      title: t('SETTINGS.new-tour-booked'),
      field: 'tourBooked'
    },
    {
      title: t('SETTINGS.message-reminder'),
      field: 'messageReminder'
    }
  ]

  const [form, setForm] = useState(
    user && user.notificationSettings
      ? user.notificationSettings
      : {
          tourReminder: true,
          rateReminder: true,
          suggestionsForFollowings: true,
          popularTourSuggestions: true,
          newFollower: true,
          referralUpdates: true,
          tourBooked: true,
          messageReminder: true
        }
  )
  const [dataChanged, setDataChange] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (user && user.notificationSettings) {
      setForm(user.notificationSettings)
    }
  }, [user])

  const onChange = value => {
    setForm(prevForm => ({
      ...prevForm,
      [value]: !prevForm[value]
    }))
    setDataChange(true)
  }

  useEffect(() => {
    async function changeSetting() {
      setLoading(true)

      const changeNotificationSettings = await (
        await import('actions/user/changeNotificationSettings')
      ).default

      dispatch(
        changeNotificationSettings({
          data: {
            notificationSettings: form
          },
          action: () => {
            setDataChange(false)
            setLoading(false)
          }
        })
      )
    }

    if (dataChanged) {
      changeSetting()
    }
  }, [dataChanged, dispatch, form])

  return (
    <Col noFlex>
      {notificationTypes.map(({ title, field }, index) => (
        <Row
          key={title}
          hasBorderBottom={`1px solid ${theme.GRAY_5}`}
          centerAll
          ht='65px'
          between
          noFlex
          pad='16px'
          style={
            index === 0
              ? {
                  borderTop: `1px solid ${theme.GRAY_5}`
                }
              : {}
          }
        >
          <Text weight='400' size='14px'>
            {title}
          </Text>
          <Switch
            on={form[field]}
            onClick={() => !loading && onChange(field)}
          />
        </Row>
      ))}
    </Col>
  )
}

export default NotificationSettings
