const trips = (state = null, action) => {
  const { payload, type } = action

  switch (type) {
    case 'SET_MY_TRIPS':
      return payload

    case 'CREATE_TRIP':
      const tripExists = state.find(x => x._id === payload.trip._id)
      if (tripExists) return state

      return [payload.trip, ...state]

    case 'UPDATE_TRIP':
      return (
        state &&
        state.map(x => {
          if (x._id !== payload._id) return x

          const newData = {
            ...x,
            ...payload
          }

          return newData
        })
      )

    case 'START_STREAMING':
      return state.map(x => {
        if (x._id !== payload._id) return x

        return {
          ...x,
          live: true,
          dates: x.dates.map(y => {
            if (y._id !== payload.scheduleId) return y
            if (y.startedAt) return y

            return {
              ...y,
              startedAt: payload.startedAt
            }
          })
        }
      })

    case 'GUEST_JOINED':
      if (payload.isHost) return state
      return state.map(x => {
        if (x._id !== payload.tripId) return x

        return {
          ...x,
          watchingCount: x.watchingCount ? x.watchingCount + 1 : 1
        }
      })

    case 'GUEST_LEFT':
      if (!state) return null
      return state.map(x => {
        if (x._id !== payload.tripId) return x

        const filteredWatchers = x.watchers.filter(
          watcher => watcher !== payload.userId
        )
        return Object.assign(x, {
          watchers: filteredWatchers,
          watchingCount: x.watchingCount ? x.watchingCount - 1 : 0
        })
      })

    case 'END_TRIP':
      if (!state) return state
      return state.map(x => {
        if (x._id !== payload._id) return x

        return {
          ...x,
          live: false,
          watchingCount: 0,
          dates: x.dates.map(y => {
            if (y._id !== payload.scheduleId) return y

            return {
              ...y,
              endedAt: payload.date
            }
          })
        }
      })

    case 'HOST_LEFT':
      if (!state) return null
      return state.map(x => {
        if (x.host._id !== payload) return x

        return {
          ...x,
          live: false
        }
      })

    case 'NEW_SIGNUP':
      return state.map(x => {
        if (x._id !== payload.tripId) return x

        return {
          ...x,
          dates: x.dates.map(y => {
            if (y._id !== payload.scheduleId) return y

            return { ...y, users: [payload.userId, ...y.users] }
          })
        }
      })

    case 'ADD_TRIP':
      return [payload.trip, ...state]

    case 'ADD_GUEST_TRIP':
      if (!state) {
        return [payload.trip]
      } else if (state && state.length > 0) {
        let tempTrips = [...state]
        if (
          tempTrips.some(
            trip => trip._id + '' === payload.trip._id + ''
          )
        ) {
          return state
        } else {
          return [payload.trip, ...state]
        }
      }

    case 'USER_CANCELED_TRIP':
      let tempTrips = [...state]
      tempTrips = tempTrips.map(x => {
        if (x._id !== payload.tripId) return x

        return {
          ...x,
          dates: x.dates.map(y => {
            if (y._id !== payload.scheduleId) return y

            return {
              ...y,
              users: [...y.users.filter(z => z !== payload.userId)]
            }
          })
        }
      })

      return tempTrips

    case 'LIKE_TRIP':
      const liked = state.find(x => x._id === payload.trip._id)

      // user already signed up, but just removed the like, do nothing
      if (liked && liked.guests.includes(payload.userId)) return state

      // liked and not signed up, remove
      if (liked && payload.type !== 'like') {
        return state.filter(x => x._id !== payload.trip._id)
      } else if (!liked) {
        // liked, add
        return [...state, payload.trip]
      }

    case 'DELETE_TRIP':
      return state.filter(x => x._id !== payload)

    case 'LOGOUT':
      return null

    default:
      return state
  }
}

export default trips
