const liveStreaming = (state = [], action) => {
  const { payload, type } = action
  switch (type) {
    case 'SET_HOST_LOCATION':
      return payload
    default:
      return state
  }
}

export default liveStreaming
