const initialState = {
  loadingUser: false,
  tripDetails: {},
  user: {},
  accountLink: {},
  orientation: 'PORTRAIT',
  autoRotate: false,
  isAmplitudeInit: null,
  liveTripUsers: {},
  playerFullScreen: false,
  recorderSettings: {
    state: false,
    recorderId: null,
    resourceId: null,
    sid: null
  },
  screenDimensions: {
    width: 0,
    height: 0,
    isMobile: false
  }
}

const details = (state = initialState, action) => {
  const { payload, type } = action
  switch (type) {
    case 'LOADING_USER':
      return {
        ...state,
        loadingUser: payload
      }

    case 'SET_SINGLE_TRIP':
      const { tripDetails } = payload
      return (state = {
        ...state,
        tripDetails
      })

    case 'SET_SINGLE_USER':
      const { user } = payload
      return (state = {
        ...state,
        user
      })

    case 'SET_STRIPE_LINK':
      const { accountLink } = payload
      return {
        ...state,
        accountLink
      }

    case 'SET_ORIENTATION':
      const { orientation } = payload
      return {
        ...state,
        orientation
      }
    case 'SET_AUTO_ROTATE':
      const { autoRotate } = payload
      return {
        ...state,
        autoRotate
      }

    case 'SET_IS_AMPLITUDE_INIT':
      const { isAmplitudeInit } = payload
      return (state = {
        ...state,
        isAmplitudeInit
      })

    case 'SET_LIVE_TRIP_USERS':
      let tempLiveTripUser = state.liveTripUsers
        ? { ...state.liveTripUsers }
        : {}
      tempLiveTripUser[payload.tripId] = payload.liveTripUsers
      return {
        ...state,
        liveTripUsers: tempLiveTripUser
      }

    case 'SET_RECORDER_SETTINGS':
      const { recorderSettings } = payload
      return (state = {
        ...state,
        recorderSettings
      })

    case 'SET_PLAYER_FULLSCREEN':
      return {
        ...state,
        playerFullScreen: payload
      }

    case 'SET_SCREEN_DIMENSION':
      return {
        ...state,
        screenDimensions: payload
      }
    default:
      return state
  }
}

export default details
