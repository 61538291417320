import React, { useEffect, useState } from 'react'
import styled, { keyframes } from 'styled-components'
import { useDispatch } from 'react-redux'

import Icon from './Icon'
import Text from './Text'

import theme from 'lib/theme'

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`

const fadeOut = keyframes`
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
`

const ToastAbs = styled.div`
  position: fixed;
  width: 100%;
  left: 0;
  display: flex;
  justify-content: center;
  ${props =>
    props.position === 'top'
      ? `top: ${props.positionSize ? props.positionSize : '20px'};`
      : props.position === 'center'
      ? `top: ${props.positionSize ? props.positionSize : '20px'};`
      : `bottom:${props.positionSize ? props.positionSize : '20px'};`}

  z-index: 9999;
`

const ToastDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: '100%';
  animation: ${props => (props.fade === 'in' ? fadeIn : fadeOut)} 0.3s
    ease;
`
const ToastContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px;
  background: ${props => (props.color ? props.color : theme.BLACK)};
  border-radius: 10px;
  width: 375px;
`

const StyledIcon = styled(Icon)`
  ${props =>
    props.toast_type &&
    props.toast_type === 'success' &&
    'filter: brightness(10);'}
`

function Toast({
  msg,
  type,
  show,
  dismissToast,
  position,
  positionSize,
  timer
}) {
  const toastTypes = {
    success: {
      containerColor: theme.GREEN,
      iconType: 'blue-tick',
      textColor: theme.WHITE
    },
    error: {
      containerColor: '#f9cccc',
      iconType: 'close-red-circle',
      textColor: theme.RED
    },
    warning: {
      containerColor: '#fff2cc',
      iconType: 'warning',
      textColor: '#ffc000'
    },
    info: {
      containerColor: '#edf0fd',
      iconType: 'info-blue',
      textColor: '#4169e1'
    }
  }
  const dispatch = useDispatch()
  const [fade, setFade] = useState('in')
  useEffect(() => {
    let intervalTimer = null
    if (show && fade === 'in') {
      if (fade === 'out') setFade('in')
      intervalTimer = setTimeout(
        () => {
          setFade('out')
        },
        timer ? timer : 3000
      )
    }
    return () => {
      clearInterval(intervalTimer)
    }
  }, [show, dismissToast, fade, timer])

  const settings = toastTypes[type ? type : 'info']

  return (
    <React.Fragment>
      {show && (
        <ToastAbs position={position} positionSize={positionSize}>
          <ToastDiv
            fade={fade}
            onAnimationEnd={() => {
              if (fade === 'out' && show) {
                if (dismissToast) {
                  dismissToast()
                } else {
                  dispatch({
                    type: 'RESET_SHOW_TOAST'
                  })
                }
                setFade('in')
              }
            }}
          >
            <ToastContainer color={settings.containerColor}>
              <StyledIcon
                type={settings.iconType}
                size='20px'
                marg='0 0.5rem 0 0'
                toast_type={type}
              />
              <Text
                color={settings.textColor}
                size='16px'
                height='22px'
                weight='700'
                QS
              >
                {msg}
              </Text>
            </ToastContainer>
          </ToastDiv>
        </ToastAbs>
      )}
    </React.Fragment>
  )
}

export default Toast
