import styled from 'styled-components'
import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'

import Row from 'components/Row'
import Col from 'components/Col'
import Text from 'components/Text'
import Avatar from 'components/Avatar'

const RowWrapper = styled.div`
  cursor: pointer;
  &:hover {
    background-color: rgba(196, 196, 196, 0.2);
  }
`

function Trips({ trips, handleClose }) {
  const router = useRouter()
  const { t } = useTranslation('common')

  if (trips?.length <= 0) return null
  return (
    <Col>
      <Text
        pad='0px 20px'
        weight='700'
        color='#ABABAB'
        lineHeight='14px'
        size='12px'
      >
        {t('GENERAL.tours')}
      </Text>
      <Row marg='10px 0 10px 0'>
        <Col>
          {trips?.length > 0 &&
            trips.map((singleTrip, i) => (
              <RowWrapper
                key={i}
                onClick={() => {
                  // history.push(`/search-results/${singleTrip.name}`, {
                  //   searchText: singleTrip.name,
                  //   searchParams: {
                  //     city: singleTrip.city,
                  //     country: singleTrip.country
                  //   }
                  // })
                  router.push(`/explore/${singleTrip._id}`)
                  handleClose()
                }}
              >
                {/* <Link
                  to={{
                    pathname: '/search-result',
                    stateData: {
                      userSearch: singleTrip.name,
                      city: singleTrip.city,
                      country: singleTrip.country,
                    },
                  }}
                  style={{ textDecoration: 'none' }}
                > */}
                <Row center pad='8px 20px'>
                  {singleTrip?.covers[0].includes('.mp4') ? (
                    <video
                      style={{
                        width: 45,
                        height: 45,
                        objectFit: 'cover',
                        borderRadius: 10
                      }}
                    >
                      <source
                        src={singleTrip?.covers[0]}
                        type='video/mp4'
                      />
                    </video>
                  ) : (
                    <Avatar
                      size={45}
                      hasRadius='10px'
                      noCircle
                      uri={
                        singleTrip?.covers[0]?.length < 20
                          ? `https://img.youtube.com/vi/${singleTrip?.covers[0]}/0.jpg`
                          : singleTrip?.covers[0]
                      }
                    />
                  )}

                  <Text
                    weight='600'
                    lineHeight={'19px'}
                    size={16}
                    marg='0px 0px 0px 12px'
                  >
                    {singleTrip.name}
                  </Text>
                </Row>
                {/* </Link> */}
              </RowWrapper>
            ))}
        </Col>
      </Row>
    </Col>
  )
}

export default Trips
