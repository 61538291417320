const defaultState = null

const state = (state = defaultState, action) => {
  const { payload, type } = action

  switch (type) {
    // keeping this in case we'll need a global err res handler
    case 'SET_URL_STATE':
      return {
        ...payload
      }

    case 'CLEAR_URL_STATE':
      return null

    default:
      return state
  }
}

export default state
