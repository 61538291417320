const user = (state = null, action) => {
  const { payload, type } = action

  switch (type) {
    case 'SET_USER':
      return payload

    case 'UPDATE_USER':
      return {
        ...state,
        ...payload
      }

    case 'UPDATE_HOST_DETAILS':
      return {
        ...state,
        hostDetails: { ...payload.hostDetails }
      }

    case 'LIKE_TRIP':
      const liked = state.savedTrips?.find(
        x => x === payload.trip._id
      )

      if (liked) {
        return {
          ...state,
          savedTrips: state.savedTrips.filter(
            x => x !== payload.trip._id
          )
        }
      }

      return {
        ...state,
        savedTrips: [...state.savedTrips, payload.trip._id]
      }

    case 'ADD_TRIP':
      return {
        ...state,
        signedUp: [payload.newSchedule, ...state.signedUp]
      }

    // TODO: update with user._id later on when we have paymentHistory dashboard
    case 'USER_CANCELLED_TRIP':
      return {
        ...state,
        accountBalance: state.accountBalance - payload.price * 100,
        paymentHistory: state.paymentHistory.map(x => {
          if (
            x.scheduleId !== payload.scheduleId &&
            x.user !== payload.user
          )
            return x

          return {
            ...x,
            cancelled: true
          }
        })
      }

    case 'CANCEL_TRIP':
      return {
        ...state,
        signedUp: state.signedUp.map(x => {
          if (x?.schedule?.scheduleId !== payload.scheduleId) return x

          return {
            ...x,
            cancelled: true
          }
        })
      }

    case 'NEW_SIGNUP':
      if (!payload.newPayment) return state

      return {
        ...state,
        accountBalance:
          state.accountBalance + payload.newPayment.amount,
        paymentHistory: [payload.newPayment, ...state.paymentHistory]
      }

    case 'ADD_SEARCH_RESULT':
      return {
        ...state,
        recentSearches: [payload, ...state.recentSearched]
      }

    case 'FOLLOW_USER':
      return {
        ...state,
        followingCount: state.followingCount + 1,
        following: [payload, ...state.following]
      }

    case 'NEW_FOLLOWER':
      return {
        ...state,
        notifCount: state.notifCount + 1,
        followersCount: state.followersCount + 1,
        followers: [payload.data, ...state.followers],
        notifications: [payload.notifData, ...state.notifications]
      }

    case 'NEW_NOTIFICATION':
      return {
        ...state,
        notifCount: state.notifCount + 1,
        notifications: [payload, ...state.notifications]
      }

    case 'MARK_NOTIF_READ':
      return {
        ...state,
        notifications: state.notifications.map(x => {
          if (x._id !== payload) return x

          return {
            ...x,
            read: true
          }
        })
      }

    case 'UNFOLLOWED':
      return {
        ...state,
        followersCount: state.followersCount - 1,
        followers: state.followers.filter(x => x._id !== payload)
      }

    case 'UNFOLLOW_USER':
      return {
        ...state,
        followingCount: state.followingCount - 1,
        following: state.following.filter(x => x._id !== payload._id)
      }
    case 'REMOVE_SAVED_TRIPS':
      return {
        ...state,
        savedTrips: state.savedTrips.filter(x => !payload.includes(x))
      }

    case 'ADD_RECENT_SEARCH':
      const filteredSearches = state.recentSearches.filter(
        x => x._id !== payload._id
      )

      return {
        ...state,
        recentSearches: [payload, ...filteredSearches]
      }

    case 'SIGNUP_DONE':
      return {
        ...state
      }

    case 'ADD_CARD':
      return {
        ...state,
        cards: [payload, ...state.cards]
      }

    case 'LOGOUT':
      return null

    default:
      return state
  }
}

export default user
