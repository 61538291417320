import React from 'react'
import styled, { css } from 'styled-components'
import { textStyles } from 'styles'

const StyledText = styled.p`
  ${props => props.QS && 'font-family: Quicksand'};
  ${props => textStyles(props, css)};

  ${props => props.weight && `font-weight: ${props.weight};`}
  ${props => props.cursorPointer && `cursor:pointer;`};
  ${props =>
    props.textDecoration &&
    `text-decoration: ${props.textDecoration};`}
  ${props =>
    (props.bold || props.inputHeader) && `font-weight: bold;`}
  ${props =>
    props.textOverflow &&
    `
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  `}
  ${props =>
    props.noSelect &&
    `
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                     supported by Chrome, Edge, Opera and Firefox */
  `}
`

const MyText = props => <StyledText {...props} />

export default MyText
