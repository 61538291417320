import { useRouter } from 'next/router'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

const usePush = () => {
  const dispatch = useDispatch()
  const router = useRouter()

  function pushWithState(url, state, replace) {
    dispatch({
      type: 'SET_URL_STATE',
      payload: state
    })

    replace ? router.replace(url) : router.push(url)
  }

  const emptyState = useCallback(() => {
    dispatch({
      type: 'CLEAR_URL_STATE'
    })
  }, [dispatch])

  return {
    pushWithState,
    emptyState
  }
}
export default usePush
