const theme = {
  BLACK: '#1E0407',
  FULL_BLACK: '#000000',
  BLACK_TRALLY: '#22191A',
  NEUTRAL_DARK: '#2C2C2C',
  TRALLY_RED: '#E5295B',
  WHITE: '#ffffff',
  BLUE: '#4E6BEE',
  BLUE_1: '#2F80ED',
  BLUE_2: '#17B8D5',
  BLUE_3: '#1890FF',
  BLUE_4: '#4E6BEE',
  BLUE_5: '#006AFF',
  BLUE_6: '#596AFF',
  BLUE_7: '#CCD1FF',
  BLUE_8: '#000640',
  BLUE_9: '#CFD0DC',
  LIGHT_BLUE: '#EDF0FD',
  LIGHT_BLUE_2: '#F2F4FF',
  LIGHT_BLUE_3: 'rgba(118, 122, 154, 0.4)',
  LIGHT_BLUE_4: '#3A3C4E',
  PURE_RED: '#FF0000',
  RED: '#EB5757',
  RED_2: '#DC2626',
  LIGHT_RED: '#FFF2F2',
  LIGHT_RED_2: '#FADDDD',
  GRAY_5: '#E0E0E0',
  GRAY_6: '#F7FAFC',
  GRAY_7: '#F7F7F7',
  GRAY_6: '#F2F2F2',
  GRAY_20: '#BDBDBD',
  GRAY_40: '#828282',
  GRAY_60: '#4F4F4F',
  GRAY_77: '#C4C4C4',
  GRAY_90: '#E3E3E3',
  GRAY_21: '#8D8D8D',
  GRAY_22: '#D9D9D9',
  PURPLE: '#4E6BEE',
  DANGER: '#E24448',
  DANGER_BG: '#FECACA',
  CYAN_8: '#DFF0F2',
  DARK_CYAN: '#4299A3',
  TRALLY_DARK_TEAL: '#00D6CA',
  DARK_TEAL_20: '#DFF1F2',
  LIGHT_TEAL_20: '#DBECEA',
  LIGHT_TEAL: '#EDFCFB',
  DARK_GREEN: '#185D6E',
  TRALLY_GREEN: '#2AB5B1',
  LIGHT_GREEN: '#E6FBFA',
  LIGHT_GREEN_2: '#D7FFFD',
  LIGHT_GREEN_3: '#DFF0F2',
  LIGHT_GREEN_4: '#8FDFDA',
  LIGHT_GREEN_5: '#6BFFF7',
  GREEN: '#01D6CA',
  gray1: '#FAFAFA',
  gray2: '#ABABAB',
  gray3: '#EEEEEE',
  gray4: '#F1F1F1',
  gray5: 'rgba(30, 4, 7, 0.5)', // used for rounded input placeholder
  gray6: '#707070', // used for normal input placeholder
  gray7: '#F9F9F9',
  gray8: '#F6F6F6',
  gray9: '#989898',
  gray10: '#F0EFEF',
  gray11: '#A9A9A9',
  gray12: '#D3D1D1',
  gray13: '#E5E5E5',
  gray14: '#959595',
  gray15: '#8B8B8B',
  gray16: '#CACACA',
  gray17: '#6B6B6B',
  gray18: '#EAEAEA',
  gray19: '#F4F3F3',
  gray20: '#E3E8ED',
  gray21: '#697885',
  gray22: '#9C9C9C',
  gray23: '#A4A4A4',
  gray24: '#929292',
  gray25: 'rgba(56, 56, 56, 0.9)',
  gray26: 'rgba(34, 25, 26, 0.5)',
  gray27: '#E9E9E9',
  gray28: 'rgba(51, 51, 51, 0.8)',
  gray29: '#E8E8E8',
  gray30: '#3A3C4E',
  gray31: '#A6A9BD',
  gray32: '#CFD0DC',
  gray33: '#BBBCCC',
  gray34: '#E4E4EB',
  GRAY_SUIT: '#BBBCCC',
  PLAYBACK: '#767A9A',
  DARK_PINK: '#FF1455',
  LIGHT_GRAY: '#DADADA',
  LIGHT_YELLOW: '#FFF3E1',
  ORANGE: '#FF9B0C',
  TITAN_WHITE: '#EDF0FD',
  MENTION_HIGHLIGHT_ORANGE: '#FFCE8E',
  MENTION_SELECTION: '#D5DAF2',
  TURQUOISE: '#6BFFF7',
  DARK_TURQUOISE: '#00A197',
  DARK_TURQUOISE_2: '#00D6CA'
}

export default theme
