const defaultState = {
  loadingButton: null,
  searchResults: null,
  searchText: null,
  tripData: {},
  tags: [],
  appLang: null,
  authModalVisible: false,
  reFreshOnBlock: false,
  totalTip: null,
  redirectTo: null,
  sessionStatus: null,
  isSocketDisconnected: false,
  lastScreen: null,
  deviceId: null,
  liveTripId: null,
  guest: null,
  downloadPopupStatus: {},
  tripDates: {
    previousDate: null,
    currentDate: null,
    isChanged: false
  },
  auth: {
    authVisible: false,
    loginVisible: false,
    registerVisible: false,
    usernameVisible: false,
    redirect: false
  },
  showToast: {
    show: false,
    message: '',
    type: 'success' //sucees, error, warning, info
  }
}

const appState = (state = defaultState, action) => {
  const { payload, type } = action

  switch (type) {
    // keeping this in case we'll need a global err res handler
    case 'HANDLE_RES':
      return {
        ...state,
        errRes: {
          err: payload.err ? payload.err : null,
          res: payload.res ? payload.res : null,
          isErr: payload.isErr
        }
      }

    case 'LOADING_BUTTON':
      return {
        ...state,
        loadingButton: payload
      }

    case 'SET_DOWNLOAD_POPUP_STATUS':
      return {
        ...state,
        downloadPopupStatus: payload
      }

    case 'REFRESH_ON_BLOCK':
      return {
        ...state,
        reFreshOnBlock: !state.reFreshOnBlock
      }

    case 'SET_SESSION_STATUS':
      return {
        ...state,
        sessionStatus: payload
      }

    case 'SET_AUTH_REDIRECT':
      return {
        ...state,
        auth: {
          ...state.auth,
          redirect: payload
        }
      }
    case 'SET_AUTH_VISIBLE':
      return {
        ...state,
        auth: {
          ...state.auth,
          authVisible: payload
        }
      }
    case 'SET_LOGIN_VISIBLE':
      return {
        ...state,
        auth: {
          ...state.auth,
          loginVisible: payload
        }
      }
    case 'SET_REGISTER_VISIBLE':
      return {
        ...state,
        auth: {
          ...state.auth,
          registerVisible: payload
        }
      }
    case 'SET_USERNAME_VISIBLE':
      return {
        ...state,
        auth: {
          ...state.auth,
          usernameVisible: payload
        }
      }

    case 'TOGGLE_AUTH_MODAL': {
      return {
        ...state,
        authModalVisible: !state.authModalVisible
      }
    }
    case 'SET_REDIRECT': {
      return {
        ...state,
        redirectTo: payload ? payload : null
      }
    }

    case 'SET_TAGS':
      return {
        ...state,
        tags: payload
      }

    case 'CREATE_TAG':
      if (state && state.tags && state.tags.length > 0) {
        const tagExists = state.tags.find(
          x => x._id === payload.tag._id
        )
        if (tagExists) return state.tags

        return {
          ...state,
          tags: [...state.tags, payload.tag]
        }
      }
      return {
        ...state,
        tags: [payload.tag]
      }

    case 'TOGGLE_CREATE_TRIP':
      return {
        ...state,
        creatingTrip: payload
      }

    case 'SET_TRIP_DATA':
      return {
        ...state,
        tripData: payload
      }
    case 'SET_TRIP_DATES':
      return {
        ...state,
        tripDates: {
          ...payload
        }
      }

    case 'SET_APP_LANG':
      return {
        ...state,
        appLang: payload
      }

    case 'LOGOUT':
      return defaultState

    case 'SEARCH':
      return { ...state, searchResults: payload }

    case 'SET_SEARCH':
      return { ...state, searchText: payload }

    case 'SET_GUEST':
      return { ...state, guest: payload }

    case 'UPDATE_GUEST':
      return {
        ...state,
        guest: {
          ...state.guest,
          ...payload
        }
      }

    case 'CLEAR_SEARCH':
      return { ...state, searchResults: null }

    case 'IS_SOCKET_DISCONNECTED':
      return { ...state, isSocketDisconnected: payload }

    case 'SET_TOTAL_TIP':
      const value = state.totalTip
        ? parseInt(state.totalTip) + parseInt(payload)
        : payload
      return { ...state, totalTip: value }

    case 'SET_LAST_SCREEN': {
      return {
        ...state,
        lastScreen: payload
      }
    }

    case 'RESET_TOTAL_TIP':
      return { ...state, totalTip: 0 }

    case 'SET_LIVE_TRIP_ID':
      return { ...state, liveTripId: payload }

    case 'SET_DEVICE_ID':
      return { ...state, deviceId: payload }

    case 'SET_SHOW_TOAST':
      return { ...state, showToast: payload }

    case 'RESET_SHOW_TOAST':
      return {
        ...state,
        showToast: {
          show: false,
          message: '',
          type: 'success' //sucees, error, warning, info
        }
      }

    default:
      return state
  }
}

export default appState
