import styled from 'styled-components'
import useTranslation from 'next-translate/useTranslation'

import Text from 'components/Text'
import Row from 'components/Row'
import Col from 'components/Col'

const Span = styled.span`
  margin-left: ${props => (props.noMargin ? '0' : '4px')};
  font-size: 14px;
  padding: 0 0 2px;
  line-height: 16px;
  font-weight: ${props => (props.bold ? '700' : '400')};
`
const ItemText = ({ item }) => {
  let { type, notificationType } = item
  const { t } = useTranslation('common')

  return type === 'editTour' ||
    type === 'scheduled' ||
    type === 'tripLive' ? (
    <Row wrap='yes'>
      <Col>
        <Text bold size='14px' pad='0 0 2px'>
          {t('SETTINGS.tour-reminder')}
        </Text>
        {type === 'editTour' ? (
          <Text size='14px' pad='0 0 2px'>
            The tour {item.tripName ? item.tripName : ''} has been
            rescheduled to {item?.schedule?.date}{' '}
            {item?.schedule?.time} by {item.username}
          </Text>
        ) : type === 'tripLive' ? (
          <>
            <Text size='14px' pad='0 0 2px'>
              {item.tripName ? item.tripName : ''} is now live.
            </Text>
          </>
        ) : (
          <>
            <Text size='14px' pad='0 0 2px'>
              {item.tripName ? item.tripName : ''} is now available
            </Text>
            <Text size='14px' pad='0 0 2px'>
              Book this tour now
            </Text>
          </>
        )}
      </Col>
    </Row>
  ) : (
    <Row wrap='yes'>
      {type === 'welcome' && (
        <Col>
          <Text pad='0 0 2px' size='14px' lineHeight='16px' bold>
            {t('GENERAL.welcome')}
          </Text>
          <Text pad='0 0 2px' size='14px'>
            {' Your future notifications will be displayed here.'}
          </Text>
        </Col>
      )}

      {type === 'messageReminder' && (
        <Col>
          <Text bold size='14px' pad='0 0 2px'>
            {t('SETTINGS.chat')}
          </Text>
          <Text size='14px' pad='0 0 2px'>
            {item.username}
            <Span>{t('NOTIFICATION.sent-new-message')}</Span>
          </Text>
        </Col>
      )}
      {type === 'follow' && (
        <Col size='14px' pad='0 0 2px'>
          <Text bold size='14px' pad='0 0 2px'>
            {t('SETTINGS.activities')}
          </Text>
          <Span noMargin>
            {item.username} {t('NOTIFICATION.started-following')}
          </Span>
        </Col>
      )}

      {type === 'publish' && (
        <Col>
          <Text bold size='14px' pad='0 0 2px'>
            {item.username}
          </Text>
          <Text bold size='14px' pad='0 0 2px'>
            <Span noMargin>{t('NOTIFICATION.published-trip')}</Span>
          </Text>
        </Col>
      )}

      {type === 'signUp' && (
        <Col>
          <Text bold size='14px' pad='0 0 2px'>
            {t('SETTINGS.new-tour-booked')}
          </Text>
          <Text size='14px' pad='0 0 2px'>
            {item.username}
            <Span>{t('NOTIFICATION.has-booked')}</Span>
            <Span noMargin>{item.tripName}</Span>
            <Span noMargin>.</Span>
          </Text>
        </Col>
      )}

      {type === 'review' && (
        <Col>
          <Text bold size='14px' pad='0 0 2px'>
            {t('NOTIFICATION.trip-review')}
            <Span>{t('NOTIFICATION.tell-about')}</Span>
          </Text>
        </Col>
      )}

      {(type === 'aboutToStart' ||
        notificationType === 'tourReminder') && (
        <Col>
          <Text bold size='14px' pad='0 0 2px'>
            {t('SETTINGS.tour-reminder-start')}
          </Text>
          <Text size='14px' pad='0 0 2px'>
            {item.tripName}
            <Span>{t('NOTIFICATION.description')}</Span>
          </Text>
        </Col>
      )}
    </Row>
  )
}

export default ItemText
