import Col from './Col'
import Spinner from './Spinner'

function Loading() {
  return (
    <Col centerAll wid='100%' ht='100vh' noFlex>
      <Spinner />
    </Col>
  )
}

export default Loading
