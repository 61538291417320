const defaultState = {
  chats: [],
  chatMessages: [],
  refresh: false,
  activeChat: null,
  hasNewChat: false
}

const chat = (state = defaultState, action) => {
  const { payload, type } = action
  switch (type) {
    case 'SET_HAS_NEW_CHAT':
      return {
        ...state,
        hasNewChat: payload
      }

    case 'SET_CHAT_REFRESH':
      return {
        ...state,
        hasNewChat: true,
        refresh: payload
      }

    case 'EMPTY_CHAT':
      return {
        ...state,
        chats: []
      }

    case 'SET_CHAT':
      return {
        ...state,
        chats: [...payload]
      }

    case 'NEW_CHAT':
      return {
        ...state,
        chats: [...state.chats, payload]
      }

    case 'UPDATE_CHAT':
      let findTempChats = state.chats.filter(
        c => c._id === payload._id
      )

      if (findTempChats && findTempChats.length > 0) {
        let tempChat = findTempChats[0]
        tempChat = {
          ...tempChat,
          ...payload
        }

        let foundIndex = state.chats.findIndex(
          x => x._id === payload._id
        )

        let newChats = state.chats
        newChats[foundIndex] = tempChat

        return {
          ...state,
          chats: newChats
        }
      }

    case 'SET_LATEST_MESSAGE':
      let findChats = state.chats.filter(
        c => c._id === payload.chatId
      )

      if (findChats && findChats.length > 0) {
        let tempChat = findChats[0]
        tempChat['latestMessage'] = payload
        let tempChatsWithout = state.chats.filter(
          c => c._id !== payload.chatId
        )
        return {
          ...state,
          chats: [tempChat, ...tempChatsWithout]
        }
      }

    case 'EMPTY_CHAT_MESSAGES':
      return {
        ...state,
        chatMessages: []
      }

    case 'SET_CHAT_MESSAGES':
      return {
        ...state,
        chatMessages:
          payload && payload.length > 0 ? [...payload] : []
      }

    case 'SET_ACTIVE_CHAT':
      return {
        ...state,
        activeChat: payload
      }

    case 'NEW_CHAT_MESSAGE':
      if (state && state.activeChat + '' === payload.chatId + '') {
        return {
          ...state,

          chatMessages: [...state.chatMessages, payload]
        }
      } else {
        return state
      }

    case 'OLD_BULK_CHAT_MESSAGES':
      return {
        ...state,
        chatMessages: Array.isArray(payload)
          ? [...payload, ...state.chatMessages]
          : [payload, ...state.chatMessages]
      }

    case 'NEW_BULK_CHAT_MESSAGES':
      return {
        ...state,
        chatMessages: [...state.chatMessages, ...payload]
      }
    default:
      return state
  }
}

export default chat
