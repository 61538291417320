import styled from 'styled-components'
import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'
import Image from 'next/image'

import Col from 'components/Col'
import Text from 'components/Text'
import Row from 'components/Row'
import Clickable from 'components/Clickable'
import Icon from 'components/Icon'

import env from 'lib/env'
import theme from 'lib/theme'

const HoverIcon = styled(Icon)`
  filter: opacity(0.5);
  &:hover {
    filter: opacity(1);
  }
`

const FooterStyle = styled.footer`
  bottom: 0;
  width: 100%;
  height: 250px;
  background-color: ${theme.LIGHT_GREEN};
  color: black;
  text-align: center;
  padding: ${props => (props.smallDesktop ? '0 30px' : '0 145px')};
`

const FooterIos = styled(Image)`
  height: 38px;
  width: 130px;
  margin: 0 12px 0 0;
`

const FooterAndroid = styled(Image)`
  height: 43px;
  width: 130px;
`
const Footer = () => {
  const { t } = useTranslation('common')
  const router = useRouter()
  return (
    <FooterStyle smallDesktop={window.innerWidth < 1204}>
      <Col centerAll>
        <Row wid='100%'>
          <Row
            pad='0 0 35px 0 '
            style={{
              flex: 0.7
            }}
          >
            <Row centerAll noFlex minWid='106px'>
              <Clickable onClick={() => router.push('/explore')}>
                <Image
                  src={require('assets/real-logo.png')}
                  width={100}
                  alt='trally logo'
                />
              </Clickable>
            </Row>
            <Row center marg='0 0 0 5vw'>
              <Clickable
                onClick={() => window.open(env.FAQ_URL, '_blank')}
              >
                <Text
                  weight={700}
                  size={'14px'}
                  marg='0 30px 0 0 '
                  QS
                >
                  {t('GENERAL.faq')}
                </Text>
              </Clickable>
              <Clickable
                onClick={() =>
                  window.open(env.FEEDBACK_URL, '_blank')
                }
              >
                <Text
                  weight={700}
                  size={'14px'}
                  marg='0 30px 0 0'
                  QS
                  color={theme.BLUE}
                >
                  {t('GENERAL.hear-your-feedback')}
                </Text>
              </Clickable>
              <Clickable
                onClick={() =>
                  window.open(env.CONTACTUS_URL, '_blank')
                }
              >
                <Text weight={700} size={'14px'} QS>
                  {t('GENERAL.contact-us')}
                </Text>
              </Clickable>
            </Row>
          </Row>
          <Row
            style={{
              flex: 0.3
            }}
          >
            <Col>
              <Row marg='40px 0 0 0' center endAll>
                <Clickable
                  onClick={() => window.open(env.FACEBOOK, '_blank')}
                >
                  <HoverIcon
                    type='facebook-black'
                    marg='0 18px 0 0 '
                    size='20px'
                  />
                </Clickable>
                <Clickable
                  onClick={() => window.open(env.YOUTUBE, '_blank')}
                >
                  <HoverIcon
                    type='youtube'
                    marg='0 18px 0 0'
                    size='20px'
                  />
                </Clickable>
                <Clickable
                  onClick={() => window.open(env.LINKEDIN, '_blank')}
                >
                  <HoverIcon
                    type='linkedIn'
                    size='20px'
                    marg='0 5px 0 0'
                  />
                </Clickable>
              </Row>
              <Row endAll pad='35px 0 0 0 ' center>
                <Clickable
                  onClick={() =>
                    window.open(env.APPSTORE_URL, '_blank')
                  }
                >
                  <FooterIos
                    src={require('assets/ios.png')}
                    alt='Trally iOS'
                  />
                </Clickable>
                <Clickable
                  onClick={() =>
                    window.open(env.PLAYSTORE_URL, '_blank')
                  }
                >
                  <FooterAndroid
                    priority
                    src={require('assets/andriod.png')}
                    alt='Trally Android'
                  />
                </Clickable>
              </Row>
            </Col>
          </Row>
        </Row>
        <Row
          marg='62px 0 0  0'
          wid='100%'
          style={{
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <Text
            size='14px'
            weight='500'
            color={theme.GRAY_60}
            lineHeight='22px'
            spacing='0.5px'
          >
            © Trally 2021. All rights reserved.
          </Text>
        </Row>
      </Col>
    </FooterStyle>
  )
}

export default Footer
