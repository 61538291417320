import React, { useState, useEffect, useMemo } from 'react'
import styled from 'styled-components'
import useTranslation from 'next-translate/useTranslation'
import { useDispatch, useSelector } from 'react-redux'
import { useRouter } from 'next/router'
import Image from 'next/image'

import Row from 'components/Row'
import Col from 'components/Col'
import Text from 'components/Text'
import Clickable from 'components/Clickable'

import theme from 'lib/theme'
import { logEvent } from 'core/amplitude'

const BottomContainer = styled(Row)`
  border-top: 1px solid ${theme.gray4};
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: ${theme.WHITE};
  height: 75px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 22px;
  z-index: 999;
`

const Icon = styled(Image)`
  width: ${props => props.size && props.size};
  height: ${props =>
    props.ht ? props.ht : props.size && props.size};
`

const BottomIcon = ({
  icon,
  size,
  text,
  active,
  setActive,
  menu,
  link,
  router,
  user,
  showStatusToast
}) => {
  const dispatch = useDispatch()
  return (
    <Clickable
      onClick={() => {
        setActive(menu)
        if (menu === 'create-trip') {
          logEvent({
            name: 'Create a Tour',
            type: 'click'
          })
          if (!user) {
            router.push('/auth')
          } else if (
            user &&
            user.type === 'host' &&
            (user.status === 'active' ||
              ((user?.status === 'first_trial' ||
                user?.status === 'second_trial') &&
                user?.quota > 0))
          ) {
            dispatch({
              type: 'SET_TRIP_DATA',
              payload: {}
            })
            router.push(link)
          } else if (
            user &&
            user.type === 'host' &&
            (user?.status === 'first_trial' ||
              user?.status === 'second_trial') &&
            user?.quota <= 0
          ) {
            router.push('/account-pending')
          } else {
            showStatusToast()
          }
        } else {
          router.push(link)
        }
      }}
    >
      <Col centerAll>
        <Icon src={icon} size={size} alt={menu} />
        {text && text.length > 0 && (
          <Text
            weight='500'
            size='9px'
            lineHeight='12.64px'
            color={active ? theme.BLACK_TRALLY : theme.GRAY_20}
            marg='5px 0 0 0'
          >
            {text}
          </Text>
        )}
      </Col>
    </Clickable>
  )
}

function BottomNavigation() {
  const { t } = useTranslation('common')
  const router = useRouter()
  const dispatch = useDispatch()
  const [active, setActive] = useState('explore')
  const user = useSelector(state => state.user)

  const menuItems = useMemo(
    () => [
      {
        icon: require('assets/explore-grey.png'),
        activeIcon: require('assets/explore-active.png'),
        size: '26px',
        text: t('GENERAL.explore'),
        menu: 'explore',
        link: '/explore'
      },
      {
        icon: require('assets/trips-grey.png'),
        activeIcon: require('assets/trips-active.png'),
        size: '26px',
        text: t('GENERAL.trips'),
        menu: 'tours',
        link: '/tours/upcoming'
      },
      {
        icon: require('assets/create.png'),
        size: '45px',
        menu: 'create-trip',
        link: '/create-tour/name'
      },
      {
        icon: require('assets/saved-grey.png'),
        activeIcon: require('assets/saved-active.png'),
        size: '26px',
        text: t('GENERAL.saved'),
        menu: 'saved-tours',
        link: '/saved-tours'
      },
      {
        icon: require('assets/profile-grey.png'),
        activeIcon: require('assets/profile-active.png'),
        size: '26px',
        text: t('GENERAL.profile'),
        menu: 'profile',
        link: '/profile'
      }
    ],
    [t]
  )

  useEffect(() => {
    let tempPath = router.pathname.substring(
      1,
      router.pathname.length
    )

    let tempUser = tempPath.substring(
      tempPath.lastIndexOf('/') + 1,
      tempPath.length
    )
    let otherUserLength = tempUser !== 'profile' ? tempUser.length : 0

    tempPath = tempPath.includes('/')
      ? tempPath.substring(0, tempPath.indexOf('/'))
      : tempPath

    if (tempPath === 'account-pending') {
      setActive('create-trip')
      return
    } else if (tempPath === '') {
      setActive('explore')
      return
    }

    let otherUser = otherUserLength > 0 && tempPath === 'profile'

    menuItems.map(item => {
      if (tempPath === item.menu) {
        setActive(item.menu)
        if (!user && item.menu !== 'explore' && !otherUser) {
          router.push('/auth')
        }
      }
    })
  }, [router.pathname, menuItems, router, user])

  const showStatusToast = () => {
    dispatch({
      type: 'SET_SHOW_TOAST',
      payload: {
        show: true,
        message:
          user.status === 'pending'
            ? t('GENERAL.host_status_pending')
            : user?.status === 'deactivated'
            ? t('GENERAL.host_status_deactivated')
            : user?.status === 'rejected'
            ? t('GENERAL.host_status_rejected')
            : '',
        type: 'error'
      }
    })
  }

  return (
    <Col marg='75px 0 0 0'>
      <BottomContainer wid='100vw' noFlex>
        {menuItems.map(item => (
          <React.Fragment key={item.menu}>
            {user &&
            user.type === 'guest' &&
            item.menu === 'create-trip' ? (
              ''
            ) : (
              <BottomIcon
                icon={
                  active === item.menu && item?.activeIcon
                    ? item?.activeIcon
                    : item?.icon
                }
                size={item?.size}
                text={item?.text}
                active={active === item.menu}
                setActive={setActive}
                menu={item.menu}
                link={item.link}
                router={router}
                user={user}
                showStatusToast={showStatusToast}
              />
            )}
          </React.Fragment>
        ))}
      </BottomContainer>
    </Col>
  )
}

export default BottomNavigation
