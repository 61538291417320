const messages = (state = {}, action) => {
  const { payload, type } = action
  switch (type) {
    case 'EMPTY_MESSAGES':
      return payload
    case 'SET_MESSAGES':
      if (Object.keys(payload).length) {
        const key = Object.keys(state).find(
          id => id === payload.tripId
        )
        if (key) {
          return {
            ...state,
            [key]: payload.messages
          }
        } else {
          return {
            ...state,
            [payload.tripId]: payload.messages
          }
        }
      }
      return state

    case 'NEW_MESSAGE':
      const tripKey = Object.keys(state).find(
        id => id === payload.tripId
      )

      if (tripKey) {
        const messages = state[tripKey]

        return {
          ...state,
          [tripKey]: [payload, ...messages]
        }
      } else {
        return {
          ...state,
          [payload.tripId]: [payload]
        }
      }

    case 'LEAVE_TRIP':
      return state

    default:
      return state
  }
}

export default messages
