const reviews = (
  state = {
    all: [],
    averageRating: 0,
    ratingCount: 0,
    options: {}
  },
  action
) => {
  const { payload, type } = action
  switch (type) {
    case 'GET_REVIEWS':
      return {
        ...state,
        all: payload.reviews,
        averageRating: payload.averageRating,
        ratingCount: payload.ratingCount,
        options: payload.options
      }
    case 'EMPTY_REVIEWS':
      return state

    default:
      return state
  }
}

export default reviews
