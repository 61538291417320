import { Fragment, useMemo, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useRouter } from 'next/router'
import Spinner from 'components/Spinner'
import Col from 'components/Col'

function RestrictRoutes(props) {
  const router = useRouter()
  const { pathname } = router
  const user = useSelector(state => state.user)
  const [checkedRoute, setCheckedRoute] = useState(false)

  const onboardingRoutes = useMemo(
    () => [
      '/success',
      '/stripe-success',
      '/stripe-error',
      '/onboarding'
    ],
    []
  )

  const nonProtectedRoutes = useMemo(
    () => [
      /^\/$/g, // Matching-> '/'
      /^\/explore$/g, // Matching-> '/explore'
      /^\/explore\/\[tripId\]$/g, // Matching-> '/explore/24chars'
      /^\/search-results\/.*$/g, // Matching-> '/search-results/texthere'
      /^\/see-all$/g, // Matching-> '/see-all'
      /^\/search$/g, // Matching-> '/search'
      /^\/live$/g, // Matching-> '/live'
      /^\/good-bye$/g // Matching-> '/good-bye'
    ],
    []
  )

  const nonUserProtectedRoutes = useMemo(
    () => [
      /^\/profile\/\[profileId\]\/?$/g, // Matching-> '/profile/24chars'
      /^\/auth$/g, // Matching-> '/auth'
      /^\/login$/g, // Matching-> '/login'
      /^\/register$/g, // Matching-> '/register'
      /^\/forgot-password$/g // Matching-> '/forgot-password'
    ],
    []
  )

  const userProtectedRoutes = useMemo(
    () => [
      /^\/profile\/?(\[profileId\]\/?)?$/g, // Matching-> '/profile' OR '/profile/[24chars]'
      /^\/profile-payments$/g, // Matching-> '/profile-payments'
      /^\/create-tour(\/.*)?$/g, // Matching-> '/create-tour/[step]'
      /^\/edit-tour\/.*$/g, // Matching-> '/edit-tour/[step]'
      /^\/edit-profile$/g, // Matching-> '/edit-profile'
      /^\/tours\/.+$/g, // Matching-> '/tours/[tourType]',
      /^\/account\/[0-z]+(\/(\[\[\.\.\.status\]\]))?$/g, // Matching-> '/account/:type/:status(success|failed)?',
      /^\/chat(\/.*)?$/g, // Matching-> '/chat/:chatId?'
      /^\/payment$/g, // Matching-> '/payment'
      /^\/payment-confirmation$/g, // Matching -> '/notifications/:notificationType'
      /^\/notifications\/.+$/g,
      /^\/settings$/g,
      /^\/saved-tours$/g,
      /^\/blocked-users$/g
    ],
    []
  )

  useEffect(() => {
    //check to see if pathname lines up with non user routes
    function checkCorrectRoute() {
      let tempCheckedRoute = true
      if (user && !user.onboarded) {
        if (!onboardingRoutes.includes(pathname))
          router.replace('/onboarding')
        else return setCheckedRoute(true)
      } else {
        tempCheckedRoute = nonProtectedRoutes.some(regex =>
          pathname.match(regex)
        )
        if (tempCheckedRoute) return setCheckedRoute(true)

        if (!user) {
          tempCheckedRoute = nonUserProtectedRoutes.some(regex =>
            pathname.match(regex)
          )
          if (!tempCheckedRoute) router.replace('/explore')
          else setCheckedRoute(true)
        } else if (user && user.onboarded) {
          tempCheckedRoute = userProtectedRoutes.some(regex =>
            pathname.match(regex)
          )
          if (!tempCheckedRoute) router.replace('/explore')
          else setCheckedRoute(true)
        }
      }
    }

    checkCorrectRoute()

    return () => {
      setCheckedRoute(false)
    }
  }, [
    user,
    pathname,
    onboardingRoutes,
    nonUserProtectedRoutes,
    nonProtectedRoutes,
    userProtectedRoutes,
    router
  ])

  if (!checkedRoute)
    return (
      <Col centerAll wid='100%' ht='100vh' noFlex>
        <Spinner />
      </Col>
    )

  return <Fragment>{props.children}</Fragment>
}

export default RestrictRoutes
