import { useState, useRef, useEffect } from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'

import Icon from 'components/Icon'
import Col from 'components/Col'
import Box from 'components/Box'
import Text from 'components/Text'

import Hosts from './components/Hosts'
import Trips from './components/Trips'
import Recents from './components/Recents'

import theme from 'lib/theme'

const InputContainer = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  background-color: ${theme.WHITE};
  position: relative;

  ${props =>
    props.rounded &&
    `
    font-size: 16px;
    letter-spacing: 0.12px;
    border-radius: 33.5px;
    padding: 0 12px;
    
 `};

  ${props => props.wid && `width: ${props.wid}`};
  ${props => props.border && `border: ${props.border}`};
  ${props => props.ht && `height: ${props.ht}`};
  ${props => props.contentCenter && `justify-content: center`};
  ${props => props.itemsCenter && `align-items: center`};

  ${props =>
    props.activeBorder
      ? `
  border: 1px solid ${theme.BLACK_TRALLY};
  `
      : `
  &:hover {
    border: 1px solid ${theme.gray16};
    filter: drop-shadow(0px 5px 15px rgba(0, 0, 0, 0.1));
  }

  ${
    props.active &&
    `border: 1px solid ${theme.BLUE} !important;
    filter: none !important;
  `
  }
  `}
`

const StyledInput = styled.input`
  width: 100%;
  height: auto;
  background-color: transparent;
  font-family: Quicksand;
  margin-left: 20px;
  font-weight: bold;
  font-size: 14px;
  line-height: 17.5px;

  ::placeholder,
  ::-webkit-input-placeholder {
    color: ${props => props.placeholderTextColor};
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17.5px;
  }
`

const IconContainer = styled.div`
  width: 42px;
  height: 42px;
  border-radius: 50%;
  background-color: ${theme.FULL_BLACK};
  align-items: center;
  display: flex;
  justify-content: center;
  position: absolute;
  cursor: pointer;
  right: 3px;

  &:hover {
    opacity: 0.9;
  }
`

let timer
const SearchInput = ({
  placeholder,
  container,
  top,
  activeBorder
}) => {
  const [text, setText] = useState('')
  const [active, setActive] = useState(false)
  const [visible, setVisible] = useState(false)
  const [searchResults, setSearchResults] = useState([])
  const wrapperRef = useRef(null)
  const { user } = useSelector(state => state)
  const router = useRouter()

  const { t } = useTranslation('common')
  useEffect(() => {
    if (!visible) {
      document.removeEventListener('mousedown', handleClickOutside)
      return
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [visible])

  function handleClickOutside(e) {
    if (wrapperRef?.current?.contains(e.target)) return
    setVisible(false)
  }

  function handleFocus() {
    setActive(true)
    setVisible(true)
  }

  function handleChange({ target: { value } }) {
    setText(value)
    clearTimeout(timer)

    timer = setTimeout(async () => {
      if (!value.length) return setSearchResults([])

      const Api = await (await import('lib/Api')).default

      const res = await Api.post('/explore/search', {
        text: value,
        id: user?._id
      })
      if (!res) return
      setVisible(true)
      setSearchResults(res)
    }, 300)
  }

  const onSearchClick = () => {
    router.push('/search-results/' + text)
  }

  return (
    <Col noFlex>
      <InputContainer
        {...container}
        active={active}
        activeBorder={activeBorder}
      >
        <StyledInput
          placeholder={placeholder}
          placeholderTextColor={theme.gray15}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={() => setActive(false)}
        />

        <IconContainer onClick={onSearchClick}>
          <Icon size='16px' type={'search-white'} />
        </IconContainer>
      </InputContainer>

      {visible && searchResults.length !== 0 && (
        <Box
          ref={wrapperRef}
          hasRadius='30px'
          wid={'365px'}
          maxHt='65vh'
          pad='20px 0px'
          overflow='auto'
          overflowX='hidden'
          overflowY='overlay'
          bg='#ffffff'
          marg='10px 0 0 0'
          hasShadow='0px 4px 24px rgba(0, 0, 0, 0.15)'
          zIndex='2'
          absolute
          top={top}
        >
          <Recents
            destinations={searchResults?.destinations}
            handleClose={() => setVisible(false)}
          />
          <Trips
            trips={searchResults?.trips}
            handleClose={() => setVisible(false)}
          />
          <Hosts
            hosts={searchResults?.hosts}
            handleClose={() => setVisible(false)}
          />
          {searchResults.isEmpty && (
            <>
              <Text
                pad='0px 20px 20px'
                weight='700'
                color='#ABABAB'
                lineHeight='14px'
                size='12px'
              >
                {searchResults?.latestTrips?.length === 0
                  ? t('SEE_ALL.no-results')
                  : t('SEARCH_TRIP.no-results-desc')}
              </Text>
              <Trips
                trips={searchResults?.latestTrips}
                handleClose={() => setVisible(false)}
              />
            </>
          )}
        </Box>
      )}
    </Col>
  )
}
export default SearchInput
