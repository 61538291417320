import styled from 'styled-components'
import { useRouter } from 'next/router'

import Clickable from 'components/Clickable'
import Text from 'components/Text'
import Col from 'components/Col'
import Row from 'components/Row'

import theme from 'lib/theme'

const BorderedCol = styled(Col)`
  ${props =>
    props.active ? `border-bottom: 2px solid ${theme.BLACK};` : ''};
  min-width: 119px;
`

const FixedRow = styled(Row)`
  position: fixed;
  z-index: 1;
  background-color: ${theme.WHITE};
`

function Navigation({
  notificationType,
  pages,
  togglePage,
  isDesktop
}) {
  const router = useRouter()
  return (
    <Col
      relative
      ht='55px'
      style={{
        minHeight: 55,
        zIndex: 2
      }}
    >
      <FixedRow
        wid={isDesktop ? '375px' : '100vw'}
        borderBottom={!isDesktop && `1px solid ${theme.GRAY_77}`}
        pad='22px 0 0 0'
      >
        {pages &&
          pages.map(page => (
            <Clickable
              key={page.type}
              onClick={() =>
                notificationType !== page.type
                  ? isDesktop
                    ? togglePage(page.type)
                    : router.push(`/notifications/${page.type}`)
                  : ''
              }
              style={{
                flex: 1
              }}
            >
              <BorderedCol
                active={notificationType === page.type}
                centerAll
                pad='0 0 9px 0'
              >
                <Text
                  QS
                  weight='700'
                  size='15px'
                  lineHeight='20px'
                  spacing='0.3px'
                  color={
                    notificationType !== page.type
                      ? `${theme.GRAY_20}`
                      : undefined
                  }
                >
                  {page.name}
                </Text>
              </BorderedCol>
            </Clickable>
          ))}
      </FixedRow>
    </Col>
  )
}

export default Navigation
