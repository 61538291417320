
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    
import { Fragment, useEffect } from 'react'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { useRouter } from 'next/router'
import Head from 'next/head'
import Script from 'next/script'

import MainHOC from 'components/MainHOC'
import ReduxConfig from '../containers'
import RestrictRoutes from 'components/RestrictRoutes'
import * as fbpixel from '../scripts/fbpixel'

import('../globals.css')
import('components/Pagination.css')
import('components/ItemCarousel.css')
import('react-responsive-carousel/lib/styles/carousel.min.css') // requires a loader

function MyApp({ Component, pageProps }) {
  const router = useRouter()
  const { asPath } = router
  const { metaTags } = pageProps

  useEffect(() => {
    // This pageview only triggers the first time (it's important for Pixel to have real information)
    fbpixel.pageview()

    const handleRouteChange = () => {
      fbpixel.pageview()
    }

    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])

  useEffect(() => {
    async function connectTagManager() {
      if (
        typeof window !== 'undefined' &&
        !window.location.href.includes('192.168') &&
        !window.location.href.includes('localhost')
      ) {
        await import('../scripts/autopilot')
        const Analytics = await (await import('analytics')).default
        const googleTagManager = await (
          await import('@analytics/google-tag-manager')
        ).default

        const analytics = Analytics({
          app: 'trally-app',
          plugins: [
            googleTagManager({
              containerId: process.env.NEXT_PUBLIC_GOOGLE_TAG_ID
            })
          ]
        })

        analytics.ready(() => console.log('Tag manager connected'))
      }
    }

    connectTagManager()
  }, [])

  useEffect(() => {
    async function initFirebaseApp() {
      const firebaseConfig = {
        apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
        authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
        databaseURL: process.env.NEXT_PUBLIC_FIREBASE_DATABASE_URL,
        projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
        storageBucket:
          process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
        messagingSenderId:
          process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
        appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID
      }

      const { getApps, initializeApp } = await import('firebase/app')

      if (!getApps().length) {
        initializeApp(firebaseConfig)
      }
    }
    initFirebaseApp()
  }, [])

  useEffect(() => {
    //importing css file only if valid path
    if (asPath === '/live') {
      import('components/pages/LiveTrip/Mentions.css')
      import('components/pages/LiveTrip/MobileMessage.css')
    } else if (
      asPath === '/create-tour/schedule' ||
      asPath === '/edit-tour/schedule'
    ) {
      import('components/pages/CreateTrip/components/Calendar.css')
      import('components/pages/CreateTrip/components/TimeInput.css')
    }
  }, [asPath])

  return (
    <Fragment>
      <Head>
        <link rel='preconnect' href='https://accounts.google.com' />
        <link rel='dns-prefetch' href='https://accounts.google.com' />
        <meta
          name='viewport'
          content='width=device-width, initial-scale=1'
        />
        <meta
          name='twitter:card'
          content='summary_large_image'
        ></meta>

        {metaTags &&
          Object.entries(metaTags).map(entry => (
            <meta
              key={entry[1]}
              property={entry[0]}
              content={entry[1]}
            />
          ))}
        <meta property='og:image:width' content='1200' />
        <meta property='og:image:height' content='630' />
      </Head>

      <GoogleOAuthProvider
        clientId={process.env.NEXT_PUBLIC_GOOGLE_SERVICE_ID}
      >
        <ReduxConfig>
          <MainHOC>
            <RestrictRoutes>
              <Component {...pageProps} />
            </RestrictRoutes>
          </MainHOC>
        </ReduxConfig>
      </GoogleOAuthProvider>
      <Script
        id='fb-pixel'
        strategy='afterInteractive'
        dangerouslySetInnerHTML={{
          __html: `
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', ${fbpixel.FB_PIXEL_ID});
          `
        }}
      />
    </Fragment>
  )
}

const __Page_Next_Translate__ = MyApp


    export default __appWithI18n(__Page_Next_Translate__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      
    });
  