// redirects notifications to designated target
const Redirector = async ({ router, data, dispatch }) => {
  const markOneRead = await (
    await import('actions/user/markOneRead')
  ).default
  // const { target, targetId, _id, type, scheduleId } = data
  const { target, targetId, _id } = data

  // mark the notif read
  dispatch(markOneRead(_id))

  // do the navigation
  switch (target) {
    case 'chat':
      dispatch({
        type: 'SET_LAST_SCREEN',
        payload: window.location.pathname
      })
      router.push(`/chat/${targetId}`)
      break

    case 'user':
      router.push(`/profile/${targetId}`)
      break

    case 'trip':
      router.push(`/explore/${targetId}`)
      break

    default:
      router.push('/explore')
  }
}

export default Redirector
