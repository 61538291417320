import { Fragment, useEffect, useState, useMemo } from 'react'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'
import { useSelector, useDispatch } from 'react-redux'

import env from 'lib/env'
import { createAmplitudeInstance } from 'core/amplitude'
import usePush from 'hooks/usePush'

import Nav from 'components/Nav'
import Footer from 'components/Footer'
import BottomNavigation from 'components/BottomNavigation'
import Toast from 'components/Toast'
import AppPopup from 'components/AppPopup'
import Loading from './Loading'

const ContentArea = dynamic(import('components/ContentArea'), {
  loading: () => Loading
})
const AuthModals = dynamic(import('./Auth/AuthModals'), {
  loading: () => Loading
})

function MainHOC(props) {
  const dispatch = useDispatch()
  const { t } = useTranslation('common')
  const { user, appState, details } = useSelector(state => state)
  const userId = useMemo(() => user && user._id, [user])
  const router = useRouter()
  const { emptyState } = usePush()
  const { asPath } = router
  const { SCREEN_WIDTH } = env
  const {
    screenDimensions = {
      width: 0,
      height: 0,
      isMobileLandscape: false,
      isMobile: false
    }
  } = details

  const sessionStatus = useSelector(
    state => state && state.appState && state.appState.sessionStatus
  )
  const showToastState = useSelector(
    state => state.appState.showToast
  )
  const {
    message = '',
    type = 'success',
    show = false
  } = showToastState || {}

  //globally calculate screen dimensions
  useEffect(() => {
    function setScreenDimensions() {
      dispatch({
        type: 'SET_SCREEN_DIMENSION',
        payload: {
          width: window.innerWidth,
          height: window.innerHeight,
          isMobileLandscape:
            'ontouchstart' in document.documentElement &&
            /mobi/i.test(navigator.userAgent),
          isMobile: window.innerWidth <= SCREEN_WIDTH
        }
      })
    }

    let timeout
    setScreenDimensions()
    const handleResize = () => {
      clearTimeout(timeout)

      timeout = setTimeout(() => {
        setScreenDimensions()
      }, 400)
    }
    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [dispatch, SCREEN_WIDTH])

  //emptying redux store state only when the user leaves the whole route system
  const [isOnPage, setIsOnPage] = useState(false)
  useEffect(() => {
    if (
      (asPath.includes('/create-tour') ||
        asPath.includes('/edit-tour')) &&
      !isOnPage
    ) {
      setIsOnPage(true)
    } else if (
      !(
        asPath.includes('/create-tour') ||
        asPath.includes('/edit-tour') ||
        asPath.includes('/live')
      ) &&
      isOnPage
    ) {
      setIsOnPage(false)
      emptyState()
    }
  }, [asPath, isOnPage, emptyState])

  //check if session expired
  useEffect(() => {
    async function expireSessionAndLogout() {
      dispatch({
        type: 'SET_SHOW_TOAST',
        payload: {
          show: true,
          message: t('ERRORS.session-expired'),
          type: 'error'
        }
      })

      const { getAuth, signOut } = await import('firebase/auth')

      const auth = await getAuth()
      await signOut(auth).catch(() => {
        console.log("Couldn't sign out")
      })

      import('actions/user/logout').then(mod => {
        const logout = mod.default
        dispatch(
          logout({
            action: () => router.push('/explore')
          })
        )
      })
    }
    if (sessionStatus && sessionStatus === 'expired' && t) {
      expireSessionAndLogout()
    }
  }, [sessionStatus, t, dispatch, router])

  //check if logged in as guest
  useEffect(() => {
    async function guestLoggedIn() {
      import('core/guestLogin').then(guestLogin => {
        guestLogin.default()
      })
    }
    if (!user) {
      guestLoggedIn()
    } else if (user && user._id) {
      dispatch({
        type: 'SET_GUEST',
        payload: null
      })
    }
  }, [user, dispatch])

  useEffect(() => {
    async function getData() {
      import('actions/explore/getExplore').then(mod => {
        const getExplore = mod.default
        dispatch(getExplore(userId))
      })
      import('actions/explore/getPublicPastTours').then(mod => {
        const getPublicPastTours = mod.default
        dispatch(getPublicPastTours(userId))
      })
      import('actions/explore/getCategories').then(mod => {
        const getCategories = mod.default
        dispatch(getCategories(userId))
      })

      if (userId) {
        import('actions/user/loginWithToken').then(mod => {
          const loginWithToken = mod.default
          dispatch(loginWithToken())
        })
      }
    }

    getData()
  }, [appState.reFreshOnBlock, userId, dispatch])

  //init amplitude
  useEffect(() => {
    if (
      (!user || (user && user.email && user.name && user._id)) &&
      env.API_URL.includes('trally.com')
    ) {
      createAmplitudeInstance(
        user
          ? {
              isLoggedIn: user ? true : false,
              email: user.email,
              name: user.name,
              is_host: user && user.type && user.type === 'host',
              signup_date:
                user && user.createdAt
                  ? new Date(user.createdAt)
                  : '-',
              userId: user._id
            }
          : {}
      )
    } else {
      console.log('[Amplitude] Tracking disabled in Development mode')
    }
  }, [user])

  useEffect(() => {
    dispatch({
      type: 'LOADING_BUTTON',
      payload: null
    })

    // handleAppLanguage(appLang, dispatch)
  }, [dispatch, user])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [asPath])

  let noFooter = [
    '/login',
    '/register',
    '/auth',
    '/edit-profile',
    '/forgot-password',
    '/onboarding',
    '/payment',
    '/payment-confirmation',
    '/live',
    '/notifications',
    '/search',
    '/search-results',
    '/settings',
    '/profile-payments',
    '/create-tour',
    '/edit-tour',
    '/chat',
    '/success',
    '/good-bye'
  ]
  let noFooterRegex = [
    /^\/explore\/[A-z0-9]{24}$/g,
    /^\/create\-tour\/.*$/g,
    /^\/edit\-tour\/.*$/g,
    /^\/chat\/.*$/g,
    /^\/account\/.*$/g
  ]

  let noHeader = [
    '/login',
    '/register',
    '/auth',
    '/forgot-password',
    '/onboarding',
    '/live',
    '/success'
  ]

  let footerRegexMatch = noFooterRegex.some(regex => {
    return asPath.match(regex)
  })

  const noTopNav =
    (details && details.playerFullScreen) ||
    (asPath && noHeader.includes(asPath)) ||
    (user && !user.onboarded) ||
    screenDimensions.isMobile ||
    screenDimensions.isMobileLandscape

  return (
    <Fragment>
      <AppPopup />
      {noTopNav ? '' : <Nav router={router} />}
      <Toast
        msg={message}
        type={type}
        show={show}
        position='top'
        positionSize='74px'
      />
      <ContentArea
        style={
          user && !user.onboarded
            ? {}
            : {
                minHeight: noTopNav ? '100vh' : 'calc(100vh - 79px)',
                width: '100vw'
              }
        }
      >
        {props.children}
      </ContentArea>

      {(asPath && noFooter.some(nf => asPath.includes(nf))) ||
      (user && !user.onboarded) ||
      footerRegexMatch ||
      (details && details.playerFullScreen) ? (
        ''
      ) : screenDimensions.isMobile ? (
        <BottomNavigation />
      ) : (
        <Footer />
      )}
      <AuthModals />
    </Fragment>
  )
}

export default MainHOC
