import { useSelector } from 'react-redux'
import styled, { css } from 'styled-components'
import { buttonStyles } from 'styles'
import Text from 'components/Text'
import Icon from 'components/Icon'
import Spinner from 'components/Spinner'
import Box from 'components/Box'
import theme from 'lib/theme'

const StyledButton = styled.button`
  display: flex;
  flex-direction: row;
  cursor: pointer;

  &:hover {
    ${props =>
      props.isTransparent
        ? `background-color: ${theme.gray7}`
        : 'opacity: 0.9'};
  }

  &:active {
    ${props =>
      props.isTransparent &&
      `background-color: ${theme.gray8} !important`};
    opacity: 0.7 !important;
  }

  ${props => buttonStyles(props, css)}
`

const NewMessageIndicator = props => (
  <Box
    style={{
      position: 'relative'
    }}
  >
    <Box
      wid={'9px'}
      ht='9px'
      hasRadius='9px'
      bg={theme.RED}
      absolute
      left='9px'
      top='-1px'
      zIndex={99}
    />
    <Icon type={props.icon} size={props.text ? '18px' : '20px'} />
  </Box>
)

const MyButton = props => {
  const { loadingButton } = useSelector(({ appState }) => appState)
  const loading = loadingButton && loadingButton === props.action

  return (
    <StyledButton
      {...props}
      loading={loading ? 'true' : null}
      isTransparent={
        (!props.bgColor && !props.black && !props.bg) ||
        (props.bgColor &&
          (props.bgColor === 'transparent' ||
            props.bgColor === '#ffffff' ||
            props.bgColor === '#fff')) ||
        (props.bg &&
          (props.bg === 'transparent' ||
            props.bg === '#ffffff' ||
            props.bg === '#fff'))
      }
    >
      {props.verified && <Icon type={'complete'} size={'18px'} />}
      {props.icon &&
        !loading &&
        (props.hasNewChats ? (
          <NewMessageIndicator {...props} />
        ) : (
          <Icon
            type={props.icon}
            size={props.text ? '18px' : '20px'}
            style={{
              marginLeft: props.verified ? 14 : 0
            }}
          />
        ))}

      {loading ? (
        <Spinner
          dimensions={props.spinnerSize}
          type={props.spinner ? props.spinner : false}
        />
      ) : props.text ? (
        <Text
          style={{
            fontWeight: 'bold',
            color: props.color
              ? props.color || theme.GRAY_40
              : (props.disabled && theme.GRAY_20) ||
                (props.black ? theme.WHITE : theme.BLACK),
            marginLeft: props.icon ? 13 : 0,
            fontFamily: 'Quicksand',
            fontSize: props.fontSize ? props.fontSize : 14,
            whiteSpace: props.noWrap ? 'nowrap' : 'normal'
          }}
        >
          {props.text}
        </Text>
      ) : props.children ? (
        props.children
      ) : (
        ''
      )}
    </StyledButton>
  )
}

export default MyButton
