import React, { useState, useEffect, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { differenceInHours, subDays } from 'date-fns'
import styled from 'styled-components'
import useTranslation from 'next-translate/useTranslation'

import Text from 'components/Text'
import Clickable from 'components/Clickable'
import Spinner from 'components/Spinner'
import Icon from 'components/Icon'
import Modal from 'components/Modal'
import Button from 'components/Button'
import Col from 'components/Col'
import Row from 'components/Row'

import theme from 'lib/theme'

import Item from './Item'
import Navigation from './Mobile/Navigation'
import NotificationSettings from './Mobile/NotificationSettings'

const StyledCol = styled(Col)`
  max-height: 70vh;
  overflow-y: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: ${theme.GRAY_77};
  }

  &::-webkit-scrollbar {
    width: 10px;
    background-color: ${theme.GRAY_77};
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: ${theme.gray11};
  }
`
const FixedRow = styled(Row)`
  position: fixed;
  z-index: 1;
  background-color: ${theme.WHITE};
`

function Notifications({ MenuWrapper, menuVisible }) {
  const user = useSelector(state => state.user)
  const dispatch = useDispatch()
  const { t } = useTranslation('common')
  const [page, setPage] = useState('all')
  const [pages, setPages] = useState([])
  const [loading, setLoading] = useState(false)
  const [openNotificationSettings, setOpenNotificationSettings] =
    useState(false)
  const [notifications, setNotifications] = useState([])
  const [sectionedNotification, setSectionedNotifications] = useState(
    {
      today: [],
      thisWeek: [],
      thisMonth: [],
      earlier: []
    }
  )
  const now = useMemo(() => new Date(), [])
  const weekEnd = useMemo(() => subDays(now, 7), [now])
  const monthEnd = useMemo(() => subDays(now, 23), [now])

  useEffect(() => {
    if (user && user.type === 'host' && user.status === 'active') {
      setPages([
        {
          type: 'all',
          name: 'All'
        },
        {
          type: 'activities',
          name: 'Activities'
        },
        {
          type: 'reserved',
          name: 'Reserved'
        }
      ])
    } else {
      setPages([
        {
          type: 'all',
          name: 'All'
        },
        {
          type: 'activities',
          name: 'Activities'
        }
      ])
    }
  }, [user])

  useEffect(() => {
    async function getNotifications() {
      const updateNotifications = await (
        await import('actions/user/updateNotifications')
      ).default
      setLoading(true)
      dispatch(
        updateNotifications({
          action: () => {
            setLoading(false)
          }
        })
      )
    }
    getNotifications()
  }, [dispatch])

  useEffect(() => {
    setLoading(true)
    let tempNotifications = []
    if (page === 'all') {
      tempNotifications =
        user.notifications?.length > 1
          ? user?.notifications
              ?.sort(
                (a, b) =>
                  new Date(b.createdAt) - new Date(a.createdAt)
              )
              .filter(
                x =>
                  !user.notificationSettings ||
                  !x.notificationType ||
                  user.notificationSettings[`${x.notificationType}`]
              )
          : user.notifications.filter(
              x =>
                !user.notificationSettings ||
                !x.notificationType ||
                user.notificationSettings[`${x.notificationType}`]
            ) || []
    } else if (page === 'reserved') {
      tempNotifications =
        user.notifications?.length > 1
          ? user?.notifications
              ?.sort(
                (a, b) =>
                  new Date(b.createdAt) - new Date(a.createdAt)
              )
              .filter(
                x =>
                  (user.notificationSettings &&
                    user.notificationSettings.tourBooked &&
                    x.notificationType &&
                    (x.notificationType === 'tourBooked' ||
                      x.type === 'signUp')) ||
                  (!user.notificationSettings && x.type === 'signUp')
              )
          : user.notifications.filter(
              x =>
                (user.notificationSettings &&
                  user.notificationSettings.tourBooked &&
                  x.notificationType &&
                  (x.notificationType === 'tourBooked' ||
                    x.type === 'signUp')) ||
                (!user.notificationSettings && x.type === 'signUp')
            ) || []
    } else if (page === 'activities') {
      tempNotifications =
        user.notifications?.length > 1
          ? user?.notifications
              ?.sort(
                (a, b) =>
                  new Date(b.createdAt) - new Date(a.createdAt)
              )
              .filter(
                x =>
                  (user.notificationSettings &&
                    x.notificationType &&
                    user.notificationSettings.tourBooked &&
                    (x.notificationType === 'tourBooked' ||
                      x.type === 'signUp')) ||
                  (user.notificationSettings.newFollower &&
                    (x.notificationType === 'newFollower' ||
                      x.type === 'follow')) ||
                  (!user.notificationSettings &&
                    (x.type === 'signUp' || x.type === 'follow'))
              )
          : user.notifications.filter(
              x =>
                (user.notificationSettings &&
                  x.notificationType &&
                  user.notificationSettings.tourBooked &&
                  (x.notificationType === 'tourBooked' ||
                    x.type === 'signUp')) ||
                (user.notificationSettings.newFollower &&
                  (x.notificationType === 'newFollower' ||
                    x.type === 'follow')) ||
                (!user.notificationSettings &&
                  (x.type === 'signUp' || x.type === 'follow'))
            ) || []
    }

    const today = tempNotifications.filter(
      x => differenceInHours(now, new Date(x.updatedAt)) < 24
    )

    const thisWeek = tempNotifications.filter(
      x =>
        new Date(x.updatedAt) < subDays(now, 1) &&
        new Date(x.updatedAt) > weekEnd
    )

    const thisMonth = tempNotifications.filter(
      x =>
        new Date(x.updatedAt) < subDays(now, 7) &&
        new Date(x.updatedAt) > monthEnd
    )

    const earlier = tempNotifications.filter(
      x => new Date(x.createdAt) < subDays(now, 23)
    )

    setNotifications(tempNotifications)
    setSectionedNotifications({ today, thisWeek, thisMonth, earlier })
    setLoading(false)
  }, [
    user,
    user?.notifications,
    page,
    user?.notificationSettings,
    monthEnd,
    now,
    weekEnd
  ])

  const readAllNotification = async () => {
    const markNotifsSeen = await (
      await import('actions/user/markNotifsSeen')
    ).default
    dispatch({
      type: 'LOADING_BUTTON',
      payload: 'readingAllNotifs'
    })
    dispatch(
      markNotifsSeen({
        action: () => {
          dispatch({
            type: 'LOADING_BUTTON',
            payload: null
          })
        }
      })
    )
  }

  const togglePage = val => {
    setPage(val)
  }

  const toggleOpenNotificationSettings = () => {
    setOpenNotificationSettings(!openNotificationSettings)
  }

  return (
    <MenuWrapper
      className='menuWrapper'
      visible={menuVisible === 'notifications'}
    >
      <Row relative noFlex ht='60.11px'>
        <FixedRow
          between
          center
          ht='60.11px'
          pad='0px 13px 0px 13px'
          wid='375px'
          borderBottom={`1px solid ${theme.gray4}`}
        >
          <Text
            weight='700'
            QS
            size='15px'
            lineHeight='20px'
            spacing='0.3px'
          >
            {t('GENERAL.notifications')}
          </Text>
          <Row noFlex center>
            <Button
              onClick={() => readAllNotification()}
              noShadow
              bgColor='transparent'
              bg='transparent'
              pad='0 10px'
              marg='0 16px 0 0'
              action='readingAllNotifs'
            >
              <Text
                weight='600'
                size='12px'
                lineHeight='14.32px'
                spacing='0.5px'
                textDecorationLine='underline'
                color={`${theme.BLUE_1}`}
              >
                {t('NOTIFICATION.read-all')}
              </Text>
            </Button>
            <Clickable
              onClick={() => toggleOpenNotificationSettings()}
            >
              <Icon type='settings' size='28px' />
            </Clickable>
          </Row>
        </FixedRow>
      </Row>
      <Navigation
        isDesktop
        notificationType={page}
        t={t}
        pages={pages}
        togglePage={togglePage}
      />
      {loading ? (
        <Col
          style={{
            minHeight: 100
          }}
          centerAll
        >
          <Spinner />
        </Col>
      ) : !notifications ||
        (notifications && notifications.length <= 0) ? (
        <Col
          centerAll
          bg={theme.gray8}
          style={{
            minHeight: 100
          }}
        >
          <Text
            weight='700'
            size='12px'
            lineHeight='14.32px'
            color={theme.GRAY_60}
          >
            {t('NOTIFICATION.no-notifications')}
          </Text>
          <Text
            weight='400'
            size='14px'
            lineHeight='18.27px'
            spacing='0.5px'
            color={theme.GRAY_20}
            wid='275px'
            marg='8px 0 0 0'
          >
            {t('NOTIFICATION.no-notifications-desc')}
          </Text>
        </Col>
      ) : (
        sectionedNotification &&
        (sectionedNotification?.today?.length > 0 ||
          sectionedNotification?.thisWeek?.length > 0 ||
          sectionedNotification?.thisMonth?.length > 0 ||
          sectionedNotification?.earlier?.length > 0) && (
          <StyledCol noFlex relative>
            {sectionedNotification?.today &&
              sectionedNotification?.today.length > 0 && (
                <NotificationRow
                  title='TODAY'
                  t={t}
                  setLoading={setLoading}
                  data={sectionedNotification?.today}
                />
              )}
            {sectionedNotification?.thisWeek &&
              sectionedNotification?.thisWeek.length > 0 && (
                <NotificationRow
                  title='THIS WEEK'
                  t={t}
                  setLoading={setLoading}
                  data={sectionedNotification?.thisWeek}
                />
              )}
            {sectionedNotification?.thisMonth &&
              sectionedNotification?.thisMonth.length > 0 && (
                <NotificationRow
                  title='THIS MONTH'
                  t={t}
                  setLoading={setLoading}
                  data={sectionedNotification?.thisMonth}
                />
              )}
            {sectionedNotification?.earlier &&
              sectionedNotification?.earlier.length > 0 && (
                <NotificationRow
                  title='EARLIER'
                  t={t}
                  setLoading={setLoading}
                  data={sectionedNotification?.earlier}
                />
              )}
          </StyledCol>
        )
      )}
      <Modal
        visible={openNotificationSettings}
        closeModal={() => toggleOpenNotificationSettings()}
        borderRadius='10px'
        noModalPadding={true}
      >
        <Col centerAll wid='100%' marg='24px 0 0'>
          <Text
            weight='700'
            size='18px'
            lineHeight='20px'
            spacing='0.3px'
            QS
          >
            {t('NOTIFICATION.notification-settings')}
          </Text>
          <Col wid='100%' noFlex marg='22px 0 0'>
            <NotificationSettings />
          </Col>
        </Col>
      </Modal>
    </MenuWrapper>
  )
}

const NotificationRow = ({ title, data, t, setLoading }) => {
  return (
    <React.Fragment>
      <Row
        pad='28px 0px 15px 23px'
        flexEnd
        borderBottom={`1px solid ${theme.gray4}`}
        ht='54px'
        wid='375px'
      >
        <Text
          h6
          weight='700'
          size='12px'
          lineHeight='14.32px'
          color={`${theme.gray9}`}
        >
          {title}
        </Text>
      </Row>
      {data.map((item, i) => (
        <Item
          data={item}
          key={item?._id}
          t={t}
          setLoading={setLoading}
          location={
            i === 0
              ? 'first'
              : i >= data.length - 1
              ? 'last'
              : 'between'
          }
          isDesktop={true}
        />
      ))}
    </React.Fragment>
  )
}

export default Notifications
