const trip = (state = null, action) => {
  const { payload, type } = action

  switch (type) {
    case 'SET_TRIPS':
      return payload

    case 'TRIPS_BY_NAME':
      return payload

    case 'DESTINATION_SEARCH':
      return payload

    case 'ADD_TRIPS':
      if (!state.some(x => x._id === payload._id)) {
        return [...state, payload]
      }
      return state

    case 'UPDATE_TRIP':
      return state.map(x => {
        if (x._id !== payload._id) return x

        return {
          ...x,
          ...payload
        }
      })

    case 'USER_CANCELLED_TRIP':
      let tempTrips = [...state]
      tempTrips = tempTrips.map(x => {
        if (x._id !== payload.tripId) return x

        return {
          ...x,
          dates: x.dates.map(y => {
            if (y._id !== payload.scheduleId) return y

            return {
              ...y,
              users: [...y.users.filter(z => z !== payload.userId)]
            }
          })
        }
      })

      return tempTrips

    case 'GUEST_JOINED':
      return state.map(x => {
        if (x._id !== payload.tripId) return x

        return {
          ...x,
          watchingCount: x.watchingCount ? x.watchingCount + 1 : 1
        }
      })

    case 'GUEST_LEFT':
      return state.map(x => {
        if (x._id !== payload.tripId) return x

        const filteredWatchers = x.watchers.filter(
          watcher => watcher !== payload.userId
        )
        return Object.assign(x, {
          watchers: filteredWatchers,
          watchingCount: x.watchingCount ? x.watchingCount - 1 : 0
        })
      })

    case 'END_TRIP':
      return state.map(x => {
        if (x._id !== payload._id) return x

        return {
          ...x,
          live: false,
          watchingCount: 0,
          dates: x.dates.map(y => {
            if (y._id !== payload.scheduleId) return y

            return {
              ...y,
              endedAt: payload.date
            }
          })
        }
      })

    case 'HOST_LEFT':
      return state.map(x => {
        if (x.host._id !== payload) return x

        return {
          ...x,
          live: false
        }
      })

    case 'REMOVE_PRIVATE_TRIP':
      return state.filter(x => x._id !== payload)

    case 'DELETE_TRIP':
      return state.filter(x => x._id !== payload)

    default:
      return state
  }
}

export default trip
