import { Fragment, useEffect } from 'react'
import Col from 'components/Col'
import Clickable from 'components/Clickable'
import Icon from 'components/Icon'

import styled from 'styled-components'
import theme from 'lib/theme'

const Overlay = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
`

const Container = styled.div`
  width: 100vw;
  height: ${props =>
    props.fullHt ? '100%' : props.ht ? props.ht : 'auto'};
  background-color: ${props => (props.bg ? props.bg : theme.WHITE)};
  position: fixed;
  bottom: 0;
  ${props => props.scroll && `overflow-y: scroll;`}
`

const CloseButton = styled(Clickable)`
  ${props =>
    props.fullHt
      ? `
    position: fixed;
    right: 0;
    top: 0;
    z-index: 1;
    padding: 20px;
  `
      : `
  position: absolute;
  right: 26px;
  top: 19px;
  `}
`

function ModalSheet({
  toggleModal,
  visible,
  fullHt,
  noCloseButton,
  children,
  overflowModal,
  bg,
  ht
}) {
  useEffect(() => {
    if (visible) {
      document.body.style.position = 'fixed'
      document.body.style.top = `-${window.scrollY}px`
    } else {
      const scrollY = document.body.style.top
      document.body.style.position = ''
      document.body.style.top = ''
      window.scrollTo(0, parseInt(scrollY || '0') * -1)
    }
  }, [visible])

  if (!visible) return null
  return (
    <Overlay>
      <Container fullHt={fullHt} ht={ht} bg={bg} scroll>
        {noCloseButton ? (
          <Fragment>{children}</Fragment>
        ) : (
          <Col
            relative
            pad='22px 0'
            style={
              overflowModal
                ? {
                    overflowY: 'scroll',
                    height: '100vh'
                  }
                : {}
            }
          >
            <CloseButton
              fullHt={fullHt}
              onClick={() => {
                console.log('hello there')
                toggleModal()
              }}
            >
              <Icon type='close' size='18px' />
            </CloseButton>
            {children}
          </Col>
        )}
      </Container>
    </Overlay>
  )
}

export default ModalSheet
