import {
  Fragment,
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback
} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components'
import useTranslation from 'next-translate/useTranslation'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'

import Text from 'components/Text'
import Icon from 'components/Icon'
import Button from 'components/Button'
import Row from 'components/Row'
import Box from 'components/Box'
import Avatar from 'components/Avatar'
import Clickable from 'components/Clickable'
import Col from 'components/Col'
import ResponsiveModal from 'components/ResponsiveModal'
import SearchInput from 'components/SearchInput'
import Notifications from 'components/Notifications'

import env from 'lib/env'
import theme from 'lib/theme'

import { logCustomEvent, logEvent } from 'core/amplitude'

const NavPill = styled(Text)`
  width: 92px;
  height: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 16px;

  &:hover {
    background-color: #f9f9f9;
    border-radius: 30px;
  }
`

const ActiveLine = styled.div`
  height: 2px;
  width: 28px;
  border-radius: 2px;
  background: linear-gradient(90deg, #4e6bee 0%, #00d6ca 100%);
  margin-top: 8px;
`

const Hoverable = styled.div`
  cursor: pointer;
  ${props => props.marg && `margin: ${props.marg};`}

  &:hover {
    opacity: 0.8;
  }

  &:active {
    opacity: 0.6;
  }

  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`

const NavWrapper = styled.div`
  height: 79px;
  width: 100vw;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  background-color: ${theme.WHITE};
  box-shadow: 0px 6px 12px rgb(15, 20, 45, 0.1);
  z-index: 999;
`

const NavBar = styled.div`
  width: 100vw;
  padding: ${props =>
    props.smallDesktop ? '0 40px 0 20px' : '0 145px'};
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  a {
    margin-right: ${props => (props.isSmallDesktop ? '2vw' : '52px')};
    font-weight: bold;
    font-size: 16px;
    width: 80px;
    text-align: center;
    letter-spacing: 0.5px;
    color: ${theme.BLACK};
    font-family: Quicksand;
  }

  .active {
    background-color: ${theme.GRAY_20};
  }
`

const MenuWrapper = styled.div`
  background-color: ${theme.WHITE};
  position: absolute;
  width: 375px;
  max-height: 89vh;
  overflow-y: scroll;
  right: 0;
  top: 50px;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  ${props => !props.visible && 'display: none;'}
`

const MenuOption = styled.div`
  cursor: pointer;
  background-color: ${theme.WHITE};
  height: 56px;
  border-top: 1px solid ${theme.gray4};
  border-bottom: 1px solid ${theme.gray4};
  padding: 0 17.5px 0 17.5px;
  display: flex;
  ${props => props.disabled && 'justify-content:space-between;'}
  align-items: center;
  ${props =>
    props.lastOption &&
    `
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  `}

  &:active {
    opacity: 0.6;
  }

  &:hover {
    background-color: ${theme.gray7};
  }
`

const SettingsMenuOption = styled.div`
  cursor: pointer;
  background-color: ${theme.WHITE};
  height: 48px;
  padding: 0 16px 0 16px;
  display: flex;
  ${props => props.between && 'justify-content:space-between;'}
  align-items: center;
  ${props => props.borderTop && `border-top: ${props.borderTop};`}
  ${props =>
    props.lastOption &&
    `
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  `}

  &:active {
    opacity: 0.6;
  }

  &:hover {
    background-color: ${theme.gray7};
  }
`

const StyledText = styled(Text)`
  position: absolute;
  top: 5px;
  left: 31px;
  width: 20px;
  height: 20px;
  background-color: ${theme.TRALLY_RED};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
`

const Nav = () => {
  const router = useRouter()
  const { pathname } = router
  const [menuVisible, setMenuVisible] = useState(null)
  const user = useSelector(state => state.user)
  const dispatch = useDispatch()
  const node = useRef()
  const notificationRef = useRef()
  const { t } = useTranslation('common')
  const [showGuestToHost, setShowGuestToHost] = useState(false)
  const [activePill, setActivePill] = useState(pathname)

  const [hasChats, setHasChats] = useState(false)

  const hasNewChat = useSelector(
    state => state && state.chat && state.chat.hasNewChat
  )

  const {
    screenDimensions = {
      width: 0,
      height: 0
    }
  } = useSelector(state => state.details)

  const isSmallDesktop = useMemo(() => {
    return screenDimensions.width <= 1080
  }, [screenDimensions])

  const checkNewChats = useCallback(async () => {
    const Api = await (await import('lib/Api')).default

    const res = await Api.get('/users/hasNewChats')
    setHasChats(res)
    dispatch({
      type: 'SET_HAS_NEW_CHAT',
      payload: false
    })
  }, [dispatch])

  useEffect(() => {
    if (hasNewChat) checkNewChats()
  }, [hasNewChat, checkNewChats])

  const moveToChats = () => {
    router.push('/chat')
  }

  useEffect(() => {
    setActivePill(pathname)
  }, [pathname])

  useEffect(() => {
    if (!menuVisible) {
      document.removeEventListener('mousedown', handleClickOutside)
      return
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [menuVisible])

  function handleClickOutside(e) {
    if (node?.current?.contains(e.target)) return
    if (notificationRef?.current?.contains(e.target)) return
    setMenuVisible(null)
  }

  const changeGuestToHost = async () => {
    const changeUserType = await (
      await import('actions/user/changeUserType')
    ).default

    logCustomEvent({
      name: 'Click - Yes (Are you sure you want to be a host?)'
    })
    dispatch(
      changeUserType({
        data: {
          type: 'host',
          onboarded: false,
          onboardingStep: 0,
          convertToHost: true
        }
      })
    )
  }

  async function handleLogout() {
    const { getAuth, signOut } = await import('firebase/auth')
    const logout = await (await import('actions/user/logout')).default

    const auth = await getAuth()
    await signOut(auth).catch(() => {
      console.log("Couldn't sign out")
    })
    dispatch(
      logout({
        action: () => {
          router.push('/explore')
        }
      })
    )
  }

  function handleMenuVisible(type) {
    if (type === menuVisible) return setMenuVisible(null)
    setMenuVisible(type)
  }

  function clickActions({ route }) {
    if (route) router.push(`/${route}`)
    handleMenuVisible()
  }

  const newNotifications = useMemo(() => {
    let tempNotifications =
      user && user.notifications && user.notifications?.length > 1
        ? user.notifications.filter(
            x =>
              (!user.notificationSettings ||
                !x.notificationType ||
                user.notificationSettings[`${x.notificationType}`]) &&
              !x.read
          )
        : []

    return tempNotifications.length
  }, [user])

  const isGuest = user?.type === 'guest'

  function copyToClipboard(text) {
    var dummy = document.createElement('textarea')
    document.body.appendChild(dummy)
    dummy.value = text

    dummy.select()
    dummy.setSelectionRange(0, 99999) /* For mobile devices */

    navigator.clipboard.writeText(dummy.value)
    document.body.removeChild(dummy)
    dispatch({
      type: 'SET_SHOW_TOAST',
      payload: {
        show: true,
        message: t('PROFILE.profile-line-copied'),
        type: 'success'
      }
    })
    handleMenuVisible()
  }

  return (
    <Fragment>
      <NavWrapper>
        <NavBar
          smallDesktop={window.innerWidth < 1204}
          isSmallDesktop={isSmallDesktop}
        >
          <Row center noFlex>
            <Clickable onClick={() => router.push('/explore')}>
              <Row center noFlex minWid='106px'>
                <Image
                  src={require('assets/logo-color.png')}
                  height={30}
                  style={{
                    marginTop: 2
                  }}
                  alt='trally-logo'
                />
              </Row>
            </Clickable>
          </Row>

          <Col centerAll relative>
            <SearchInput
              container={{
                rounded: true,
                wid: isSmallDesktop ? '30vw' : '365px',
                ht: '48px',
                itemsCenter: true
              }}
              activeBorder
              placeholder='Where to?'
              top='60px'
            />
          </Col>

          <Row center noFlex>
            <Link href='/explore'>
              <Col noFlex centerAll>
                <NavPill
                  isSmallDesktop={isSmallDesktop}
                  QS
                  weight='700'
                  marg={
                    activePill === '/' || activePill === '/explore'
                      ? '9px 0 0 0'
                      : '0'
                  }
                >
                  Explore
                </NavPill>
                {(activePill === '/' ||
                  activePill === '/explore') && <ActiveLine />}
              </Col>
            </Link>

            <Link href={!user ? '/auth' : '/tours/upcoming'}>
              <Col noFlex centerAll>
                <NavPill
                  QS
                  weight='700'
                  marg={
                    activePill === '/tours/upcoming' ||
                    activePill === '/tours/past' ||
                    activePill === '/tours/cancelled' ||
                    activePill === '/tours/host'
                      ? '9px 0 0 0'
                      : '0'
                  }
                >
                  Tours
                </NavPill>
                {(activePill === '/tours/upcoming' ||
                  activePill === '/tours/past' ||
                  activePill === '/tours/cancelled' ||
                  activePill === '/tours/host') && <ActiveLine />}
              </Col>
            </Link>

            {user && user.onboarded ? (
              <Row center noFlex relative>
                <Col noFlex ref={notificationRef}>
                  <Hoverable
                    marg={isSmallDesktop ? '0 2vw 0 0' : '0 50px 0 0'}
                    onClick={() => {
                      menuVisible === 'notifications'
                        ? handleMenuVisible(null)
                        : handleMenuVisible('notifications')
                    }}
                  >
                    <Row
                      centerAll
                      noFlex
                      relative
                      wid='50px'
                      ht='50px'
                      hasRadius='50%'
                    >
                      <Icon type='notifications' size='32px' />
                      {newNotifications > 0 && (
                        <StyledText
                          weight='700'
                          size='10px'
                          lineHeight='14.05px'
                          spacing='0.5px'
                          color={`${theme.WHITE}`}
                        >
                          {newNotifications}
                        </StyledText>
                      )}
                    </Row>
                  </Hoverable>

                  <Notifications
                    MenuWrapper={MenuWrapper}
                    MenuOption={MenuOption}
                    menuVisible={menuVisible}
                    clickActions={clickActions}
                  />
                </Col>
                {!isSmallDesktop && (
                  <Col noFlex marg='0 50px 0 0'>
                    <Hoverable onClick={() => moveToChats()}>
                      <Row
                        centerAll
                        noFlex
                        relative
                        wid='50px'
                        ht='50px'
                        hasRadius='50%'
                      >
                        <Box
                          ht='9px'
                          wid={hasChats ? '9px' : 0}
                          hasRadius='9px'
                          bg={theme.RED}
                          absolute
                          right='10px'
                          top='8px'
                          zIndex={99}
                        />
                        <Icon size='32px' type='chat-outlined' />
                      </Row>
                    </Hoverable>
                  </Col>
                )}
                <Col noFlex ref={node}>
                  <Hoverable
                    onClick={() => handleMenuVisible('settings')}
                  >
                    <Avatar
                      uri={
                        user && user.avatar
                          ? !user.avatar.includes('graph.facebook') &&
                            !user.avatar.includes('google')
                            ? user.avatar +
                              '?time=' +
                              new Date().getTime()
                            : user.avatar
                          : null
                      }
                      size='48'
                      navBar
                    />
                  </Hoverable>

                  <MenuWrapper
                    className='menuWrapper'
                    visible={menuVisible === 'settings'}
                  >
                    <SettingsMenuOption
                      onClick={() =>
                        clickActions({ route: 'profile' })
                      }
                    >
                      <Text
                        QS
                        bold
                        size='16px'
                        spacing='0.3px'
                        lineHieght='20px'
                      >
                        My profile
                      </Text>
                    </SettingsMenuOption>

                    {/* <SettingsMenuOption
                    onClick={() => clickActions({ route: 'profile' })}
                    between
                  >
                    <Text QS bold size='16px'>
                      My coins
                    </Text>
                    <Row noFlex>
                      <Icon
                        type='trally-coin'
                        size='16px'
                        marg='0 6px 0 0'
                      />
                      <Text
                        weight='400'
                        size='14px'
                        lineHeight='18.27px'
                        spacing='0.5px'
                        color={theme.GRAY_60}
                      >
                        200
                      </Text>
                    </Row>
                  </SettingsMenuOption> */}

                    <SettingsMenuOption
                      onClick={() => {
                        dispatch({
                          type: 'SET_LAST_SCREEN',
                          payload: window.location.pathname
                        })
                        clickActions({ route: 'chat' })
                      }}
                    >
                      <Text QS bold size='16px'>
                        {t('CHAT.chats')}
                      </Text>
                    </SettingsMenuOption>

                    <SettingsMenuOption
                      onClick={() =>
                        clickActions({ route: 'account/edit' })
                      }
                    >
                      <Text QS bold size='16px'>
                        Account settings
                      </Text>
                    </SettingsMenuOption>
                    <SettingsMenuOption
                      onClick={() =>
                        clickActions({ route: 'blocked-users' })
                      }
                    >
                      <Text
                        QS
                        bold
                        size='16px'
                        spacing='0.3px'
                        lineHieght='20px'
                      >
                        {t('GENERAL.blocked_users')}
                      </Text>
                    </SettingsMenuOption>
                    {user?.type === 'host' && (
                      <SettingsMenuOption
                        onClick={() =>
                          copyToClipboard(
                            env.API_URL &&
                              (env.API_URL.includes('localhost') ||
                                env.API_URL.includes('192.168'))
                              ? `http://localhost:3000/profile/${user?._id}`
                              : `https://${
                                  env.API_URL &&
                                  env.API_URL.includes(
                                    'stagingapi.trally'
                                  )
                                    ? 'staging.trally.com'
                                    : 'app.trally.com'
                                }/profile/${user?._id}`
                          )
                        }
                      >
                        <Text
                          QS
                          bold
                          size='16px'
                          spacing='0.3px'
                          lineHieght='20px'
                        >
                          {t('HOST.share-profile')}
                        </Text>
                      </SettingsMenuOption>
                    )}
                    <SettingsMenuOption
                      disabled={
                        !isGuest &&
                        user?.status !== 'active' &&
                        (user?.status === 'first_trial' ||
                          user.status === 'second_trial') &&
                        user?.quota > 0
                      }
                      onClick={() => {
                        if (isGuest) {
                          logCustomEvent({
                            name: 'Click - Be a Host'
                          })
                          setShowGuestToHost(true)
                        } else if (
                          !isGuest &&
                          (((user?.status === 'first_trial' ||
                            user.status === 'second_trial') &&
                            user?.quota > 0) ||
                            user?.status === 'active')
                        ) {
                          logEvent({
                            name: 'Create a Tour',
                            type: 'click'
                          })
                          dispatch({
                            type: 'SET_TRIP_DATA',
                            payload: {}
                          })
                          clickActions({ route: 'create-tour/name' })
                        }
                      }}
                      borderTop={`2px solid ${theme.gray4}`}
                    >
                      <Row
                        style={{
                          alignItems: 'center'
                        }}
                      >
                        <Icon
                          type='add-bold'
                          size='24px'
                          marg='0 10px 0 0'
                          style={{
                            opacity:
                              (!isGuest &&
                                (((user?.status === 'first_trial' ||
                                  user.status === 'second_trial') &&
                                  user?.quota > 0) ||
                                  user?.status === 'active')) ||
                              isGuest
                                ? 1
                                : 0.3
                          }}
                        />
                        <Row
                          style={{
                            justifyContent:
                              !isGuest &&
                              (((user?.status === 'first_trial' ||
                                user.status === 'second_trial') &&
                                user?.quota > 0) ||
                                user?.status === 'active')
                                ? 'normal'
                                : 'space-between'
                          }}
                        >
                          <Text
                            QS
                            bold
                            size='16px'
                            color={
                              (!isGuest &&
                                (((user?.status === 'first_trial' ||
                                  user.status === 'second_trial') &&
                                  user?.quota > 0) ||
                                  user?.status === 'active')) ||
                              isGuest
                                ? theme.BLACK_TRALLY
                                : theme.GRAY_20
                            }
                          >
                            {!isGuest
                              ? `${t('GENERAL.create-a-trip')} `
                              : t('GENERAL.become-a-host')}
                            {/* {!isGuest
                              ? `${t('GENERAL.create-a-trip')} ${
                                  user &&
                                  (user?.status === 'first_trial' ||
                                    user?.status ===
                                      'second_trial') &&
                                  user?.quota > 0
                                    ? `(${t('SETTINGS.trial')})`
                                    : ''
                                }`
                              : t('GENERAL.become-a-host')} */}
                          </Text>
                          {!isGuest &&
                            (((user?.status === 'first_trial' ||
                              user?.status === 'second_trial') &&
                              user?.quota <= 0) ||
                              user?.status !== 'active') && (
                              <Text
                                QS
                                bold
                                size='16px'
                                color={
                                  user?.status !== 'active' &&
                                  theme.RED
                                }
                              >
                                {(user?.status === 'pending' ||
                                  ((user?.status === 'first_trial' ||
                                    user?.status ===
                                      'second_trial') &&
                                    user?.quota <= 0)) &&
                                  '(Pending Approval)'}
                                {user?.status === 'deactivated' &&
                                  '(Account Deactivated)'}
                                {user?.status === 'rejected' &&
                                  '(Request Rejected)'}
                              </Text>
                            )}
                        </Row>
                      </Row>
                    </SettingsMenuOption>
                    <SettingsMenuOption
                      disabled={!isGuest && user?.status !== 'active'}
                      onClick={() => {
                        if (!isGuest) {
                          clickActions({ route: 'tours/host' })
                        } else {
                          clickActions({ route: 'tours/upcoming' })
                        }
                      }}
                    >
                      <Row
                        style={{
                          alignItems: 'center'
                        }}
                      >
                        <Icon
                          type='location-line'
                          size='24px'
                          marg='0 10px 0 0'
                        />
                        <Text QS bold size='16px'>
                          {t('GENERAL.manage-trips-lower')}
                        </Text>
                      </Row>
                    </SettingsMenuOption>
                    <SettingsMenuOption
                      onClick={() =>
                        clickActions({ route: 'saved-tours' })
                      }
                    >
                      <Row
                        style={{
                          alignItems: 'center'
                        }}
                      >
                        <Icon
                          type='bookmark-black'
                          size='24px'
                          marg='0 10px 0 0'
                        />
                        <Text QS bold size='16px'>
                          {t('GENERAL.saved')}
                        </Text>
                      </Row>
                    </SettingsMenuOption>
                    {/* <MenuOption onClick={() => {}}>
                    <Text
                      QS
                      weight='700'
                      size='16px'
                      lineHeight='20px'
                      spacing='0.3px'
                      color={theme.BLUE}
                    >
                      {t('GENERAL.refer-friends-get-10')}
                    </Text>
                  </MenuOption> */}
                    <SettingsMenuOption
                      onClick={() =>
                        window.open(env.HELP_URL, '_blank')
                      }
                      borderTop={`2px solid ${theme.gray4}`}
                    >
                      <Text QS bold size='16px'>
                        Help
                      </Text>
                    </SettingsMenuOption>

                    <SettingsMenuOption
                      lastOption
                      onClick={handleLogout}
                    >
                      <Text QS bold size='16px'>
                        Log out
                      </Text>
                    </SettingsMenuOption>
                  </MenuWrapper>
                </Col>
              </Row>
            ) : (
              <Button
                ht='40px'
                text='Login'
                onClick={() => router.push('/auth')}
                black
              />
            )}
          </Row>
        </NavBar>
      </NavWrapper>
      <ResponsiveModal
        visible={showGuestToHost}
        toggleVisible={() => setShowGuestToHost(!showGuestToHost)}
        desktopModalHt='245px'
        desktopModalWid='565px'
      >
        <Col wid='100%' center>
          <Text
            marg='43px 0 0 0'
            QS
            weight='700'
            size='24px'
            lineHeight='30px'
          >
            {t('SETTINGS.become-a-host')}
          </Text>

          <Col>
            <Clickable>
              <Row
                centerAll
                wid='549px'
                marg='36px 0 0 0'
                borderBottom={`1px solid ${theme.gray1}`}
                borderTop={`1px solid ${theme.gray1}`}
                onClick={() => changeGuestToHost()}
              >
                <Text
                  marg='10px 0'
                  QS
                  weight='700'
                  size='18px'
                  lineHeight='25px'
                  color={`${theme.BLUE}`}
                >
                  {t('GENERAL.yes')}
                </Text>
              </Row>
            </Clickable>
            <Clickable
              onClick={() => setShowGuestToHost(!showGuestToHost)}
            >
              <Row
                centerAll
                wid='549px'
                marg='0 0 0 0'
                borderBottom={`1px solid ${theme.gray1}`}
                borderTop={`1px solid ${theme.gray1}`}
              >
                <Text
                  marg='10px 0'
                  QS
                  weight='700'
                  size='18px'
                  ineHeight='25px'
                  color={`${theme.gray2}`}
                >
                  {t('GENERAL.cancel')}
                </Text>
              </Row>
            </Clickable>
          </Col>
        </Col>
      </ResponsiveModal>
    </Fragment>
  )
}

export default Nav
