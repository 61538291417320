const pastTrips = (state = [], action) => {
  const { payload, type } = action

  switch (type) {
    case 'SET_PAST_TRIPS':
      return payload

    case 'DELETE_TRIP':
      return state.filter(
        x => x.tripId && x.tripId._id && x.tripId._id !== payload
      )

    default:
      return state
  }
}

export default pastTrips
