import React from 'react'
import styled from 'styled-components'
import theme from 'lib/theme'

const SwitchWrap = styled.div`
  background-image: ${props =>
    props.on
      ? `linear-gradient(to bottom, #4E6BEE, #00D6CA)`
      : `linear-gradient(to bottom, ${theme.GRAY_40}, ${theme.GRAY_40})`};
  width: ${props => (props.small ? '30px' : '46px')};
  height: ${props => (props.small ? '18px' : '28px')};
  border-radius: 24px;
`

const SwitchWrapper = styled.div`
  width: ${props => (props.small ? '30px' : '46px')};
  height: ${props => (props.small ? '18px' : '28px')};
  border-radius: 24px;
  position: relative;
`

const Button = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50px;
  left: ${props =>
    props.on ? (props.small ? '14px' : '19px') : '3px'};
  background: white;
  top: 2px;
  position: absolute;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15),
    0px 1px 1px rgba(0, 0, 0, 0.16), 0px 3px 1px rgba(0, 0, 0, 0.1);
`

const Switch = ({ on, onClick }) => {
  return (
    <SwitchWrapper on={on} onClick={onClick} activeOpacity={0.4}>
      <SwitchWrap on={on}>
        <Button on={on}>
          <div></div>
        </Button>
      </SwitchWrap>
    </SwitchWrapper>
  )
}

export default Switch
