import { useState, useEffect } from 'react'
import styled from 'styled-components'
import Image from 'next/image'

const MyIcon = styled(Image)`
  width: ${props => props.size || '18px'};
  height: ${props => (props.ht ? props.ht : props.size || '18px')};
  margin: ${props => props.marg || '0'};
  position: ${props => props.pos || ''};
  left: ${props => props.left};
  right: ${props => props.right};
  top: ${props => props.top};
  top: ${props => props.cursorPointer && 'pointer'};
`

const Icon = props => {
  const { type, isHovered, customHoverType } = props

  const [isMouseHovered, setIsMouseHovered] = useState(false)
  const [icon, setIcon] = useState('')
  const [hoverIcon, setHoverIcon] = useState('')
  useEffect(() => {
    async function loadIcons() {
      const icons = await (await import('lib/icons')).default
      setIcon(icons[type])
      if (isHovered)
        setHoverIcon(
          icons[customHoverType ? customHoverType : type + '-hover']
        )
    }

    loadIcons()
  }, [type, isHovered, customHoverType])

  if (!icon) return null

  //change image on hover state
  if (isHovered)
    return (
      <div
        onMouseEnter={() => setIsMouseHovered(true)}
        onMouseLeave={() => setIsMouseHovered(false)}
      >
        <MyIcon
          {...props}
          src={!isMouseHovered ? icon : hoverIcon}
          alt={type}
        />
      </div>
    )
  return <MyIcon {...props} src={icon} alt={type} />
}

export default Icon
